import React, { useState, useEffect, useRef } from 'react'
import { StepperForm } from './Utils/StepperForm/StepperForm';
import { useSearchParams } from 'react-router-dom';
import { UnauthorizedPage } from './Unauthorized';
import { LoaderContainer } from './Utils/Utils';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Drawer from '@mui/material/Drawer';
import StorageIcon from '@mui/icons-material/Storage';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { UploadForm, QueryUpdater } from "./Forms";
import { HCRC_Stringify } from './Utils/Utils';
import { Stack } from '@mui/material';
import "./Forms.css";


const ClassShaperAdmin = (props) => {

    const [historicAssumption, setHistoricAssumption] = useState([]);
    const [prevHistAssumpSubmission, setPrevHistAssumpSubmission] = useState({});
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState("");
    const [openForm, setOpenForm] = useState(false);
    const [formToShow, setFormToShow] = useState(null);
    const drawerWidth = 320;

    const handleOpenForm = (event) => {
        let optionChosen = event.target.id;
        let form = menuOptions.filter(o => o.Label === optionChosen)[0].Form;
        setFormToShow(form);
        setOpenForm(true);
    }

    const handleChangeClient = async (event) => {
        console.debug("Obtaining client specific metadata...");
        let id = event.target.value;
        let nonAdminClientToken = clients.filter(r => r.Id === id)[0].Token;

        let reqOptions = {
            method: "GET",
            headers: {
                "t": nonAdminClientToken
            }
        };

        // Obtaining most recent historic assumption form submission
        let clientMetaResp = await fetch(process.env.REACT_APP_CS_GET_HISTORIC_ASSUMPTION_DATA, reqOptions);
        let clientMetaData = await clientMetaResp.json();
        let formattedSubmission = {};
        clientMetaData.HistoricAssumptionData.forEach(r => formattedSubmission[r.HistoricAssumptionInputId] = r.HistoricAssumptionValue);
        console.debug("Client specific metadata obtained.");
        setPrevHistAssumpSubmission(formattedSubmission);
        setClient(id);
        setFormToShow(null);
    }
    console.debug("Setting menu options...");
    const menuOptions = [
        {
            Label: "Add Client",
            Form: <NewClientForm token={props.token} huVars={historicAssumption} />,
            Icon: <AddCircleIcon style={{ marginRight: "5px" }} />,
            Disabled: false
        },
        {
            Label: "Upload Data",
            Form: <UploadForm auth={props.token} client={client} />,
            Icon: <FileUploadIcon style={{ marginRight: "5px" }} />,
            Disabled: client===""
        },
        {
            Label: "Update Source Query",
            Form: <QueryUpdater auth={props.token} client={client} />,
            Icon: <StorageIcon style={{ marginRight: "5px" }} />,
            Disabled: client === ""
        },
        {
            Label: "Update Historic/Assumption Group Data",
            Form: <StepperForm
                submitHeaders={{ 'Content-Type': 'application/json', 't': props.token, 'c': client }}
                endpoint={process.env.REACT_APP_CS_SET_HISTORIC_ASSUMPTION_INPUTS}
                formData={historicAssumption}
                prevSubmission={prevHistAssumpSubmission}
                formName={"Update Historic/Assumption Group Data"}
                style={{ position: "absolute", left: "27%" }}
                onboarding={false}
            />,
            Icon: <SettingsIcon style={{ marginRight: "5px" }} />,
            Disabled: client === ""
        }
    ];

    // Effect to ran on first render only
    useEffect(() => {
        const getAdminData = async () => {
            try {
                console.debug("Obtaining Historic/Assumption form metadata and clients...");
                let reqOptions = {
                    method: "GET",
                    headers: {
                        't': props.token
                    }
                };
                let aResponse = await fetch(process.env.REACT_APP_CS_GET_HISTORIC_ASSUMPTION_INPUTS, reqOptions);
                let data = await aResponse.json();
                let jsonData = JSON.parse(data);
                setHistoricAssumption(jsonData);

                let cResp = await fetch(process.env.REACT_APP_CS_GET_CLIENTS, reqOptions);
                let cData = await cResp.json();
                let jsonCdata = JSON.parse(cData);
                setClients(jsonCdata.cpl);

                console.debug("Historic/Assumption form metadata obtained and clients.");
            }
            catch (ex) {
                console.debug("Error: " + ex);
            }
            finally {
                console.debug("Async fetch complete.");
            }

        };
        getAdminData();

        return () => {
            console.debug("Performing admin dashboard cleanup...");
            setHistoricAssumption([]);
            setFormToShow(null);
            console.debug("Admin Dashboard cleanup complete.");
        }

    }, []);

    return (
            <div>
                <AppBar
                    position="fixed"
                >
                </AppBar>
                <Drawer
                    sx={{
                        width: props.width,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: props.width,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                    open={true}
                >
                    <Stack direction="row" spacing={4}>
                        <Typography variant="h5" component="div" style={{marginLeft: "5px", marginTop: "15px", textAlign: "center" }}>
                            Options
                        </Typography>
                        <FormControl size="small">
                            <Select
                                style={{ marginTop: "10px", width: "200px", right: "0%" }}
                                displayEmpty
                                id="client-select"
                                placeholder={"Select Client"}
                                value={client}
                                onChange={handleChangeClient}
                                renderValue={(client) => {
                                    if (client === "") {
                                        return <em>Select Client</em>;
                                    }

                                    return clients.filter(r => r.Id===client)[0]["Name"];
                                }}
                                label="Client"
                            >
                                {clients.map(c => (
                                    <MenuItem key={c.Name} value={c.Id}>{c.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <hr className="solid" />
                    {menuOptions.map(option => (
                        <MenuItem disabled={option.Disabled} id={option.Label} key={option.Label} onClick={handleOpenForm}>
                            {option.Icon}
                            {option.Label}
                        </MenuItem>
                    ))}
                </Drawer>
                <Toolbar style={{ backgroundColor: "rgb(110, 110, 110)" }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ color: "rgb(255, 255, 255)", marginLeft: drawerWidth }}
                    >
                        Class Shaper Tool - Admin
                    </Typography>
                </Toolbar>
                <div id="form-selection-div" style={{marginLeft: drawerWidth, padding: "50px"}}>
                    {(openForm) ? formToShow : null}
                </div>
            </div>
    );
}


const NewClientForm = (props) => {

    // Component States
    const [clientName, setClientName] = useState("");
    const [huInputs, setHuInputs] = useState({});
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [submitting, setSubmitting] = React.useState(false);
    const fileInputRef = useRef();

    // Event Handlers
    const dropFile = (event) => {
        event.preventDefault();
        console.debug("Selecting file: " + event.dataTransfer.files[0].name);
        setFile(event.dataTransfer.files[0]);
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    const dragEnter = (event) => {
        event.preventDefault();
    }

    const dragLeave = (event) => {
        event.preventDefault();
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const selectFile = (event) => {
        event.preventDefault();
        console.debug("Selecting file: " + event.target.files[0].name);
        setFile(event.target.files[0]);
    }

    const onboardClient = async (event) => {
        console.debug("Onboarding client: " + clientName);
        setSubmitting(true);

        let formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );
        formData.append(
            "hu",
            HCRC_Stringify(huInputs)
        );

        let reqOptions = {
            method: "POST",
            headers: {
                "t": props.token,
                "n": clientName,
            },
            body: formData
        };

        try {
            let response = await fetch(process.env.REACT_APP_CS_ONBOARD_CLIENT, reqOptions);
            console.debug(response);
            setAlertMessage("Success onboarding " + clientName);
        }
        catch (exception_var) {
            console.log("Error submitting inputs");
            console.log(exception_var);
            setAlertMessage("Error submitting inputs! " + exception_var);
            setAlertSeverity("error");
        }
        finally {
            setSubmitting(false)
            setShowAlert(true)
        }
    }


    return (
        <div style={{ border: "1px solid grey", padding: "30px", borderRadius: "25px" }}>
            <h4 style={{ pading: "5px" }}>New Client Onboard</h4>
            <hr />
            <div id="client-name-container" style={{marginTop: "20px", marginBottom: "20px"}}>
                <TextField id="outlined-basic" value={clientName} onInput={(event) => setClientName(event.target.value)} label="Client Name" helperText="Example: Harvard University-MA" variant="outlined" />
            </div>
            <Accordion expanded={clientName.trim() !== ""} style={{borderRadius: "20px", border: "1px solid grey"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <SettingsIcon style={{ marginRight: "5px" }} />
                    <Typography style={{ fontWeight: "bold" }}>Historic/Assumption Group Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Fill out the following form to establish the previous year net totals, aggregates for the previous year modeling
                        population, and those same aggregates for the current/previous year assumption population
                    </Typography>
                    <hr />
                    <div id="historic-assumption-container" style={{ padding: "40px" }}>
                        <StepperForm
                            formData={props.huVars}
                            prevSubmission={{}}
                            formName={""}
                            onboarding={true}
                            sendToParent={setHuInputs}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={clientName.trim() !== "" && Object.keys(huInputs).length>0}  style={{ borderRadius: "20px", border: "1px solid grey" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <FileUploadIcon style={{ marginRight: "5px" }} />
                    <Typography style={{ fontWeight: "bold" }}>Unit Record Data (Current Year)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Click inside the dotted area or drag/drop your current year unit record file into the dotted area
                    </Typography>
                    <hr />
                    <div id="upload-file-container" style={{ padding: "20px", display: "grid", gridGap: "10px", placeItems: "left"}}>
                        <div
                            className="uploadFormDropArea"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={dropFile}
                            onClick={fileInputClicked}
                        >
                            <Stack direction="column">
                                <FileUploadIcon />
                                <input
                                    id="upload-file-input"
                                    className="uploadFormFileInput"
                                    type='file'
                                    ref={fileInputRef}
                                    onChange={selectFile}
                                />
                            </Stack>
                        </div>
                        {file ? <Alert
                            severity="info"
                            style={{width: "500px"}}
                            action={
                                <Button onClick={() => setFile(null)} color="inherit" size="small">
                                    DELETE
                                </Button>
                            }
                        >
                            {file.name}
                        </Alert> : null}
                        <TextField
                            style={{width: "500px"}}
                            id="uploadFileDescriptionInput"
                            label="Description"
                            value={description}
                            onChange={(event) => (setDescription(event.target.value))}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Fab disabled={clientName.trim() === "" || !file || Object.keys(huInputs).length === 0 || submitting || showAlert} onClick={onboardClient} style={{ marginTop: "10px", marginBottom: "10px" }} variant="extended" color="primary" aria-label="add">
                {submitting ? <CircularProgress style={{ marginRight: "5px" }} size="25px" color="info" /> : <AddIcon />}
                <Typography>Add Client</Typography>
            </Fab>
            <Alert hidden={!showAlert} severity={alertSeverity} style={{ width: "425px", height: "50px" }}>{alertMessage}</Alert>
        </div>
    );
}


export const ProtectedAdminDashboard = () => {


    const [show, setShow] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [params, setParams] = useSearchParams();
    const auth = params.get("a");

    // Effect to ran on first render only
    useEffect(() => {
        const getAdminData = async () => {
            try {

                console.debug("Authenticating user...");
                let areqOptions = {
                    method: "GET",
                    headers: {
                        "t": auth
                    }
                };

                if (auth !== null) {
                    let resp = await fetch(process.env.REACT_APP_CS_AUTHENTICATE_ADMIN, areqOptions);
                    let data = await resp.text();
                    if (data === "true") {
                        setIsAuth(true);
                        console.debug("User authenticated.");
                    }
                    else {
                        console.debug("User unauthorized.");
                    }
                }
            }
            catch (ex) {
                console.debug("Error: " + ex);
            }
            finally {
                setShow(true);
            }

        };
        getAdminData();

        return () => {
            console.debug("Cleaning up protected admin dashboard...");
            setIsAuth(false);
            setShow(false);
            console.debug("Protected admin dashboard cleanup complete.");
        }

    }, []);

    return (
        <LoaderContainer showChild={show} children={isAuth ? <ClassShaperAdmin token={auth} /> : <UnauthorizedPage />} />
    );
}