import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { Container } from 'reactstrap';
import "./Unauthorized.css";

export const UnauthorizedPage = () => {

    return (
        <Container className="unauthorizedContainer" fluid>
            <BlockIcon className="unauthorizedIcon" />
            <h3>Unauthorized Access!</h3>
        </Container>
    );
}
