import CircularProgress from '@mui/material/CircularProgress';
import { Container } from 'reactstrap';

// For json payloads to api endpoints
export const HCRC_Stringify = (jsonObj) => {

    // jsonObj is a standard javascript object

    let jsonPayload = JSON.stringify(jsonObj);
    jsonPayload = jsonPayload.replace(/'/g, "~"); // replace apostrophes on a global scale for transit
    jsonPayload = jsonPayload.replace(/"/g, "'"); // replace double quotes on a global scale with single quotes for transit
    jsonPayload = "\"" + jsonPayload + "\""; // Surround entire json string with double quotes

    return jsonPayload;
};

// returns a string object formatted to csv specs
export function jsonArrToCSV(jsonArr) {
    let headers = Object.keys(jsonArr[0]);
    let csv = "";
    csv += headers.join(",") + "\n"
    for (let i = 0; i < jsonArr.length; i++) {
        let r = jsonArr[i];
        for (let j = 0; j < headers.length; j++) {
            let header = headers[j];
            csv += r[header];
            if (j !== headers.length - 1) {
                csv += ","
            }
        };
        csv += "\n"
    };

    return csv;
}

// Formats a value to the appropriate string, very useful for data display
export function formatValue(dataFormat, value, sigFigs) {
    let formattedValue = "";
    if (value === null || isNaN(value)) {
        return dataFormat;
    }
    switch (dataFormat) {
        case "#":
            formattedValue = String(value.toFixed(sigFigs)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
        case "%":
            formattedValue = String((value * 100).toFixed(sigFigs)) + dataFormat
            break;
        case "$":
            let dollarUSLocale = Intl.NumberFormat('en-US');
            formattedValue = value >= 0 ? dataFormat + dollarUSLocale.format(value.toFixed(sigFigs)) : "-" + dataFormat + dollarUSLocale.format(Math.abs(value).toFixed(sigFigs))
            break;
        case "N":
            formattedValue = String(parseInt(value));
        default:
            formattedValue = String(value);
    }

    return formattedValue;
}

// Replaces a string representing a numeric value with empty string. This is used to avoid NaN error.
export function replaceNonNumbericStringWithEmpty(arrayOfStringsThatShouldBeNumbers) {

    return arrayOfStringsThatShouldBeNumbers = arrayOfStringsThatShouldBeNumbers.map(r => r.replace(/[^\d.-]/g, ''));
}


// SHA-256 Hashing Function that returns a promise
export async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

// Generalized Container with Loader

export const LoaderContainer = (props) => {
    const showChild = props.showChild;

    return (
        <Container fluid>
            {showChild ? props.children : <CircularProgress style={{ position: "fixed", left: "50%", top: "50%", height: "75px", width: "75px" }} />}
        </Container>
    );
}