import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import TableViewIcon from '@mui/icons-material/TableView';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import WestIcon from '@mui/icons-material/West'; // Not Kanye
import { SaveForm, LoadForm, StudentInputForm } from './Forms';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSearchParams } from "react-router-dom";
import { UnauthorizedPage } from './Unauthorized';
import { LoaderContainer } from './Utils/Utils';
import { SelectionTable } from './SelectionTable';
import { CalculateSegmentedMetrics, CalculateTotalMetrics, CalculateBubbleChartMetrics } from './CSCalculations';
import { SingleBubble, AdmitTable, PredictedMatricTable, AssumptionsTable, TotalTable } from './CSCharts';

import './ClassShaper.css';


//Column types
//To facilitate the configuration of the columns, some column types are predefined.By default, columns are assumed to hold strings, so the default column string type will be applied.As a result, column sorting will use the string comparator, and the column content will be aligned to the left side of the cell.

//The following are the native column types:

//'string'(default )
//'number'
//'date'
//'dateTime'
//'boolean'
//'singleSelect'
//'actions'

// Src: https://mui.com/x/react-data-grid/column-definition/

const Dashboard = (props) => {

    // Component States
    const [unitData, setUnitData] = useState([]);
    const [huData, setHuData] = useState([]);
    const [selectionFields, setSelectionFields] = useState([]);
    const [segmentedTableData, setSegmentedTableData] = useState({});
    const [totalTableData, setTotalTableData] = useState({});
    const [bubbleData, setBubbleData] = useState({});
    const [bubbModelNum, setBubModelNum] = useState(1);
    const [selections, setSelections] = useState([]);
    const [scenario, setScenario] = useState([])
    const [openSaveModal, setOpenSaveModal] = React.useState(false);
    const [openLoadModal, setOpenLoadModal] = React.useState(false);
    const [showOutput, setShowOutput] = useState(false);


    // Effect to ran when component is instantiated
    useEffect(() => {
        const getUnitData = async () => {
            console.debug("Obtaining unit record data, historic/assumption data, and metadata...");
            let reqOptions = {
                method: "GET",
                headers: {
                    't': props.token
                }
            };
            let uResponse = await fetch(process.env.REACT_APP_CS_GET_UNIT_RECORD_DATA, reqOptions);
            let data = await uResponse.json();
            let jsonData = JSON.parse(data);
            jsonData = jsonData.map(r => ({ ...r, id: r.Id, Admit: r.Admit ? 1 : 0 })); // giving id for MUI component and changing boolean admits to 1/0

            let mResponse = await fetch(process.env.REACT_APP_CS_GET_SELECTION_MODEL, reqOptions);
            let mData = await mResponse.json();
            mData = updateProperties(mData); // MUI component necessity , want Id field hidden and need to duplicate case sensitive property names

            let huResp = await fetch(process.env.REACT_APP_CS_GET_HISTORIC_ASSUMPTION_DATA, reqOptions);
            let huData = await huResp.json();

            console.debug("Unit record, historic/assumption data, and metadata obtained.");

            console.debug("Calculating aggregate data...");

            // Updating extravar and modeling header names
            mData = updateLabels(huData, mData);

            let segmentedCalcs = CalculateSegmentedMetrics(jsonData, huData);
            setSegmentedTableData(segmentedCalcs);

            let totalCalcs = CalculateTotalMetrics(jsonData, huData, segmentedCalcs);
            setTotalTableData(totalCalcs);

            let _bubData = CalculateBubbleChartMetrics(jsonData, bubbModelNum, huData);
            setBubbleData(_bubData);

            setUnitData(jsonData);
            setHuData(huData);
            setSelectionFields(mData);

            let _scenario = jsonData.map(r => ({ UnitId: r.StudentId, Admit: r.Admit }));
            setScenario(_scenario);

            let _selections = jsonData.filter(r => parseInt(r.Admit) === 1).map(r => r.id);
            setSelections(_selections)

            console.debug("Aggregate data calculated. All states set.");

            setShowOutput(true);

        };
        getUnitData();

        return () => {
            console.debug("Performing dashboard cleanup...");
            setUnitData([]);
            setSegmentedTableData({});
            setTotalTableData({});
            setBubbleData({});
            setSelections([]);
            setScenario([]);
            console.debug("Dashboard cleanup complete.");
        }

    }, []);

    // Checkbox handler that works for selecting and unselecting
    const handleAdmitSelection = (ids) => {
        console.debug("Updating admit selection...");
        let selectedIds = new Set(ids);
        setSelections(Array.from(selectedIds));

        let unitDataCopy = JSON.parse(JSON.stringify(unitData)); // Destroying reference to state
        for (let i = 0; i < unitDataCopy.length; i++) {
            let record = unitDataCopy[i];
            if (selectedIds.has(record.id)) {
                record.Admit = 1;
            }
            else {
                record.Admit = 0;
            }
        }

        let segmentedCalcs = CalculateSegmentedMetrics(unitDataCopy, huData);
        setSegmentedTableData(segmentedCalcs);

        let totalCalcs = CalculateTotalMetrics(unitDataCopy, huData, segmentedCalcs);
        setTotalTableData(totalCalcs);

        let _bubData = CalculateBubbleChartMetrics(unitDataCopy, bubbModelNum, huData);
        setBubbleData(_bubData);

        setUnitData(unitDataCopy);

        let _scenario = unitDataCopy.map(r => ({ UnitId: r.StudentId, Admit: r.Admit }));
        setScenario(_scenario);

        console.debug("Admint selection updated.");

    }

    // Event handler that updates the model number to use in calculations and title
    const handleBubModelChange = (event) => {
        let newModelNum = parseInt(event.target.value);
        console.debug("Updaing model number in matric bubble chart to " + String(newModelNum));
        setBubModelNum(newModelNum);

        let _bubData = CalculateBubbleChartMetrics(unitData, newModelNum, huData);
        setBubbleData(_bubData);
    }

    // Event Handlers for scenario forms
    const handleOpenSaveModal = (event) => {
        setOpenSaveModal(true);
    }

    const handleCloseSaveModal = () => {
        setOpenSaveModal(false);
    }

    const handleOpenLoadModal = (event) => {
        setOpenLoadModal(true);
    }

    const handleCloseLoadModal = () => {
        setOpenLoadModal(false);
    }

    // Function to update the extravar/modeling headernames to what was recorded in the historic/assumption form data

    function updateLabels(hu, selectionMD) {
        let updatedMD = JSON.parse(JSON.stringify(selectionMD));
        let elabels = hu.ExtravarLabels;
        let mLabels = hu.ModelLabels;
        elabels.forEach((l, i) => updatedMD.forEach(r => r.field === "Extravar" + String(i + 1) ? r.headerName = l : r.headerName = r.headerName));
        mLabels.forEach((l, i) => updatedMD.forEach(r => r.headerName.includes("Model " + String(i + 1)) ? r.headerName = r.headerName.replace("Model " + String(i + 1), l) : r.headerName = r.headerName));
        return updatedMD;
    }

    // Function to map the property names of the columns to new names since back-end uses camel case (MUI --> case sensitive)
    function updateProperties(selectionMD) {
        let newMD = JSON.parse(JSON.stringify(selectionMD));
        newMD = newMD.map(c => ({
            editable: c.Editable,
            field: c.Field,
            align: c.Align,
            headerAlign: c.HeaderAlign,
            headerName: c.HeaderName,
            hide: c.HeaderName === "ID" || c.HeaderName === "Admit" ? true : false,
            Id: c.Id,
            sortable: c.Sortable,
            type: c.Type
        }));

        return newMD;
    }

    return (
        <LoaderContainer showChild={showOutput} children={
            <Stack direction="column" spacing={12}>
                {Object.keys(bubbleData).length > 0 ? <Stack direction="row" spacing={1}>
                    {Object.keys(bubbleData).map((c, i) => (
                        <SingleBubble
                            key={bubbleData[c].title}
                            verticalLabels={bubbleData[c].verticalLabels}
                            horizontalLabels={bubbleData[c].horizontalLabels}
                            verticalTitle={bubbleData[c].verticalTitle}
                            horizontalTitle={bubbleData[c].horizontalTitle}
                            title={bubbleData[c].title}
                            data={bubbleData[c].data}
                        />
                    ))}

                    <WestIcon className="arrowIcon" />
                    <FormControl className="modelSelectForm">
                        <FormLabel id="model-radio-buttons-group-label">Model</FormLabel>
                        <RadioGroup
                            aria-labelledby="model-radio-buttons-group-label"
                            name="model-radio-buttons-group"
                            onChange={handleBubModelChange}
                            value={bubbModelNum}
                        >
                            <FormControlLabel value={1} control={<Radio />} label={huData.ModelLabels.length > 0 ? huData.ModelLabels[0]: "Model #1"} />
                            <FormControlLabel value={2} control={<Radio />} label={huData.ModelLabels.length > 1 ? huData.ModelLabels[1] : "Model #2"} />
                            <FormControlLabel value={3} control={<Radio />} label={huData.ModelLabels.length > 2 ? huData.ModelLabels[2] : "Model #3"} />
                        </RadioGroup>
                    </FormControl>
                </Stack>: null}
                <Stack direction="row" spacing={2}>
                    {Object.keys(segmentedTableData).length > 0 ? <AdmitTable totalAdmits={unitData.filter(r => r.Admit === 1).length} data={segmentedTableData} historic={huData} /> : null}
                    {Object.keys(segmentedTableData).length > 0 ? <PredictedMatricTable unitData={unitData} data={segmentedTableData} historic={huData} /> : null}
                </Stack>
                <Stack direction="row" spacing={5}>
                    <Container>
                        {huData.length === 0 ? null : <AssumptionsTable data={huData} />}
                    </Container>
                    <Container>
                        {Object.keys(totalTableData).length > 0 ? <TotalTable unitData={unitData} data={totalTableData} modelingData={segmentedTableData} hu={huData} /> : null}
                    </Container>
                </Stack>
                <Stack direction="column" spacing={8}>
                    <Container fluid className="selectTableContainer">
                        <h3>Case Level Management</h3>
                        <SelectionTable
                            rows={unitData}
                            columns={selectionFields}
                            handleSelect={handleAdmitSelection}
                            selections={selections}
                        />
                    </Container>
                    <Container fluid className="StudentInputFormContainer">
                        <StudentInputForm unitData={unitData} handleSelectMethod={handleAdmitSelection} />
                    </Container>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="outlined"
                        startIcon={<SaveIcon className="scenarioButtonIcons" />}
                        onClick={handleOpenSaveModal}
                        className="scenarioButtons"
                    >
                        Save Scenario
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<TableViewIcon className="scenarioButtonIcons" />}
                        onClick={handleOpenLoadModal}
                        className="scenarioButtons"
                    >
                        Load Scenario
                    </Button>
                </Stack>
                {openSaveModal ? <SaveForm
                    auth={props.token}
                    scenarioData={scenario}
                    closeMethod={handleCloseSaveModal}
                /> : null}
                {openLoadModal ? <LoadForm
                    auth={props.token}
                    updateSegMethod={setSegmentedTableData}
                    updateTotalMethod={setTotalTableData}
                    updateBubMethod={setBubbleData}
                    updateSelectionsMethod={setSelections}
                    updateScenarioMethod={setScenario}
                    updateUnitMethod={setUnitData}
                    unitData={unitData}
                    historicAssumptionData={huData}
                    modelNum={bubbModelNum}
                    closeMethod={handleCloseLoadModal} /> : null}
            </Stack>
        } />
    );
}


export const ProtectedDashboard = () => {

    const [show, setShow] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [params, setParams] = useSearchParams();
    const auth = params.get("a");

    // Effect to be ran at initial render
    useEffect(() => {

        const authenticate = async () => {
            try {
                console.debug("Authenticating user...");
                let reqOptions = {
                    method: "GET",
                    headers: {
                        "t": auth
                    }
                };

                if (auth !== null) {
                    let resp = await fetch(process.env.REACT_APP_CS_AUTHENTICATE_CLIENT, reqOptions);
                    let data = await resp.text();
                    if (data === "true") {
                        setIsAuth(true);
                        console.debug("User authenticated.");
                    }
                    else {
                        console.debug("User unauthorized.");
                    }
                }
            }
            catch (ex) {
                console.debug("Error: " + ex);
            }
            finally {
                setShow(true);
            }
        }

        authenticate();

        return () => {
            console.debug("Cleaning up protected dashboard...");
            setIsAuth(false);
            setShow(false);
            console.debug("Protected dashboard cleanup complete.");
        }

    }, []);

    return (
        <LoaderContainer showChild={show} children={isAuth ? <Dashboard token={auth} /> : <UnauthorizedPage />} />
    );
}