import * as React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro, GridToolbar, GridHeaderCheckbox } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

export const SelectionTable = (props) => {

    const widthFactor = 8.63;
    const resizedColumns = props.columns.map(c => ({ ...c, minWidth: (String(c.headerName).length * widthFactor) }));
    const checkboxAddedColumns = [
        {
            field: "__check__",
            type: "checkboxSelection",
            width: 165,
            sortable: false,
            align: "left",
            headerAlign: "left",
            resizable: false,
            renderHeader: (params) => (
                <React.Fragment>
                    <GridHeaderCheckbox {...params} />
                    <Typography variant="button" display="block" style={{fontWeight: "bold"}}>
                        Admit Select
                    </Typography>
                </React.Fragment>
            ),
        }
    ].concat(resizedColumns)

    return (
        <DataGridPro
            density="compact"
            rows={props.rows}
            columns={checkboxAddedColumns}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            components={{
                Toolbar: GridToolbar,
            }}
            selectionModel={props.selections}
            onSelectionModelChange={(ids) => props.handleSelect(ids)}
        />
  );

}