import { ProtectedAdminDashboard } from "./components/ClassShaperAdmin";
import { ProtectedDashboard } from "./components/ClassShaper";

const AppRoutes = [
  {
    index: true,
    element: <ProtectedDashboard />,
  },
  {
    path: '/admin',
    element: <ProtectedAdminDashboard />
  }
];

export default AppRoutes;
