// Calculations for all outputs

export function CalculateSegmentedMetrics(unitData, historicAssumptionData) {

    unitData = JSON.parse(JSON.stringify(unitData.filter(r => (r.ModelOneYield !== null) || (r.ModelTwoYield !== null) || (r.ModelThreeYield !== null)))); //Obtaining admits with yields

    // Note that Aindex and Need Index labels must be in order of numeric value in asc. order
    let aindexLabels = historicAssumptionData.AindexLabels;
    let aindexValues = unitData.map(r => parseInt(r.Acrk)).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a - b).filter((v, i) => i < aindexLabels.length);
    let needIndexLabels = historicAssumptionData.NindexLabels;
    let nindexValues = unitData.map(r => parseInt(r.Ndrk)).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a - b).filter((v, i) => i < needIndexLabels.length);
    let extravarLabels = historicAssumptionData.ExtravarLabels;
    let tuition = parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "current_tuit_fees")[0]["HistoricAssumptionValue"]);

    let admitCount = unitData.filter(r => parseInt(r.Admit) === 1).length;
    let modelOneMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0.0);
    let modelTwoMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0.0);
    let modelThreeMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0.0);

    // Last Year Data extraction
    let lyAindex = Array.from({ length: aindexLabels.length }, (v, k) => ({
        Admits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "acrk_cat" + String(k + 1) + "_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "acrk_cat" + String(k + 1) + "_matrics")[0]["HistoricAssumptionValue"]),
        Name: historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "acrk_cat" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));

    let lyNindex = Array.from({ length: needIndexLabels.length }, (v, k) => ({
        Admits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "ndrk_cat" + String(k + 1) + "_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "ndrk_cat" + String(k + 1) + "_matrics")[0]["HistoricAssumptionValue"]),
        Name: historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "ndrk_cat" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));

    let lyExtravars = Array.from({ length: extravarLabels.length }, (v, k) => ({
        Admits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_matrics")[0]["HistoricAssumptionValue"]),
        Name: historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));

    let returnData = {
        Aindex: aindexValues.map((v, i) => ({
            Label: aindexLabels[i],
            Value: v,
            Applicants: unitData.filter(r => parseInt(r.Acrk) === v).length,
            lyAdmits: lyAindex[i]["Admits"],
            Admits: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(lyAindex[i]["Admits"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: lyAindex[i]["Matrics"],
            lyYield: parseFloat(lyAindex[i]["Matrics"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Acrk) === v && parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        })),
        Need: nindexValues.map((v, i) => ({
            Label: needIndexLabels[i],
            Value: v,
            Applicants: unitData.filter(r => parseInt(r.Ndrk) === v).length,
            lyAdmits: lyNindex[i]["Admits"],
            Admits: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(lyNindex[i]["Admits"]) / parseFloat(lyNindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: lyNindex[i]["Matrics"],
            lyYield: parseFloat(lyNindex[i]["Matrics"]) / parseFloat(lyNindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Ndrk) === v && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        })),
        Male: {
            Label: "Male",
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r.Male) === 1).length,
            lyAdmits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "male_admits")[0]["HistoricAssumptionValue"]),
            Admits: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "male_admits")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "male_matrics")[0]["HistoricAssumptionValue"]),
            lyYield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "male_matrics")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0),
            ModelOneYield: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Male) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0) / modelThreeMatrics,
        },
        Black: {
            Label: "Black",
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r.Black) === 1).length,
            lyAdmits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "black_admits")[0]["HistoricAssumptionValue"]),
            Admits: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.admit) === 1).length,
            lyAdmitPerc: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "black_admits")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "black_matrics")[0]["HistoricAssumptionValue"]),
            lyYield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "black_matrics")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Black) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        },
        Hispanic: {
            Label: "Hispanic",
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r.Latinx) === 1).length,
            lyAdmits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hispanic_latino_admits")[0]["HistoricAssumptionValue"]),
            Admits: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hispanic_latino_admits")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hispanic_latino_matrics")[0]["HistoricAssumptionValue"]),
            lyYield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hispanic_latino_matrics")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Latinx) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        },
        Soc: {
            Label: "Any SOC",
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r.Soc) === 1).length,
            lyAdmits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "soc_admits")[0]["HistoricAssumptionValue"]),
            Admits: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "soc_admits")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "soc_matrics")[0]["HistoricAssumptionValue"]),
            lyYield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "soc_matrics")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.Soc) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        },
        ScoreOptional: {
            Label: "Score Optional",
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r.ScoreOptional) === 1).length,
            lyAdmits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "score_optional_count_admits")[0]["HistoricAssumptionValue"]),
            Admits: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "score_optional_count_admits")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "score_optional_count_matrics")[0]["HistoricAssumptionValue"]),
            lyYield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "score_optional_count_matrics")[0]["HistoricAssumptionValue"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r.ScoreOptional) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        },
        Extravars: extravarLabels.map((v, i) => ({
            Label: v,
            Value: 1,
            Applicants: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1).length,
            lyAdmits: lyExtravars[i]["Admits"],
            Admits: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).length,
            lyAdmitPerc: parseFloat(lyExtravars[i]["Admits"]) / parseFloat(lyAindex.map(a => a.Admits).reduce((a, b) => a + b, 0)),
            AdmitPerc: parseFloat(unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).length) / parseFloat(admitCount),
            lyMatrics: lyExtravars[i]["Matrics"],
            lyYield: parseFloat(lyExtravars[i]["Matrics"]) / parseFloat(lyAindex.map(a => a.Matrics).reduce((a, b) => a + b, 0)),
            ModelOneMatrics: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelOneYield: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelOneYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelOneMatrics,
            ModelTwoMatrics: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelTwoYield: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelTwoYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelTwoMatrics,
            ModelThreeMatrics: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            ModelThreeYield: unitData.filter(r => parseInt(r["Extravar" + String(i + 1)]) === 1 && parseInt(r.Admit) === 1).map(r => r.ModelThreeYield).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / modelThreeMatrics,
        })),
        BestComposite: {
            Label: "Best Composite SAT/ACT",
            lyAdmitAvg: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "best_comp_avg_admits")[0]["HistoricAssumptionValue"]),
            AdmitAvg: unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.BestComp)).reduce((a, b) => a + b, 0) / unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).length,
            lyMatricAvg: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "best_comp_avg_matrics")[0]["HistoricAssumptionValue"]),
            ModelOneAvg: unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.BestComp) * parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0) / unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0),
            ModelTwoAvg: unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.BestComp) * parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0) / unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0),
            ModelThreeAvg: unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.BestComp) * parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0) / unitData.filter(r => parseInt(r.Admit) === 1 && parseInt(r.ScoreOptional) === 0 && r.BestComp !== null).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0)
        },
        HsGpa: {
            Label: "High School GPA",
            lyAdmitAvg: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hsgpa_avg_admits")[0]["HistoricAssumptionValue"]),
            AdmitAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.Hsgpa !== null).map(r => parseFloat(r.Hsgpa)).reduce((a, b) => a + b, 0) / parseFloat(admitCount),
            lyMatricAvg: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "hsgpa_avg_matrics")[0]["HistoricAssumptionValue"]),
            ModelOneAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.Hsgpa !== null).map(r => parseFloat(r.Hsgpa) * parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0) / parseFloat(modelOneMatrics),
            ModelTwoAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.Hsgpa !== null).map(r => parseFloat(r.Hsgpa) * parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0) / parseFloat(modelTwoMatrics),
            ModelThreeAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.Hsgpa !== null).map(r => parseFloat(r.Hsgpa) * parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0) / parseFloat(modelThreeMatrics)
        },
        DiscountRate: {
            Label: "Discount Rate",
            lyMatricAvg: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "model_discount_rate")[0]["HistoricAssumptionValue"]),
            ModelOneAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneDiscount).map(r => parseFloat(r.ModelOneDiscount) * parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0) / parseFloat(modelOneMatrics),
            ModelTwoAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoDiscount).map(r => parseFloat(r.ModelTwoDiscount) * parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0) / parseFloat(modelTwoMatrics),
            ModelThreeAvg: unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeDiscount).map(r => parseFloat(r.ModelThreeDiscount) * parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0) / parseFloat(modelThreeMatrics)
        },
        AggregateNtr: {
            Label: "Aggregate NTR.",
            lyMatricAvg: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "model_agg_ntr")[0]["HistoricAssumptionValue"]),
            ModelOneAvg: tuition * modelOneMatrics * (1.00 - (unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneDiscount).map(r => parseFloat(r.ModelOneDiscount) * parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0) / parseFloat(modelOneMatrics))),
            ModelTwoAvg: tuition * modelTwoMatrics * (1.00 - (unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoDiscount).map(r => parseFloat(r.ModelTwoDiscount) * parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0) / parseFloat(modelTwoMatrics))),
            ModelThreeAvg: tuition * modelThreeMatrics * (1.00 - (unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeDiscount).map(r => parseFloat(r.ModelThreeDiscount) * parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0) / parseFloat(modelThreeMatrics)))
        },
        Types: {
            BestComposite: "N",
            HsGpa: "#",
            DiscountRate: "%",
            AggregateNtr: "$"
        },
        SigFigs: {
            BestComposite: 0,
            HsGpa: 2,
            DiscountRate: 1,
            AggregateNtr: 0
        }
    };

    return returnData;

}


export function CalculateTotalMetrics(unitData, historicAssumptionData, segCalcs) {

    // Assumption Group Data
    let assumptionCount = parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput==="assumption_group_count")[0]["HistoricAssumptionValue"]);
    let assumptionData = Array.from({ length: assumptionCount }, (v, k) => ({
        Admits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]),
        DiscountRate: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_discount")[0]["HistoricAssumptionValue"]),
        Name: historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));
    let assumptionMatrics = assumptionData.map(r => r.Matrics).reduce((a, b) => a + b, 0);
    let assumptionAdmits = assumptionData.map(r => r.Admits).reduce((a, b) => a + b, 0);

    // Current Year Tution Assumption
    let tuition = parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "current_tuit_fees")[0]["HistoricAssumptionValue"]);

    // Modelling Data
    let admitCount = unitData.filter(r => parseInt(r.Admit) === 1).length;
    let modelOneMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield!== null).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0.0);
    let modelTwoMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield !== null).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0.0);
    let modelThreeMatrics = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield !== null).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0.0);

    let returnData = {
        LastYear: {
            Admits: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "admits")[0]["HistoricAssumptionValue"]),
            Matrics: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "matrics")[0]["HistoricAssumptionValue"]),
            Yield: parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "matrics")[0]["HistoricAssumptionValue"]) / parseFloat(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "admits")[0]["HistoricAssumptionValue"]),
            Tution: parseInt(historicAssumptionData.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "prev_tuit_fees")[0]["HistoricAssumptionValue"])
        },
        ModelOne: {
            Admits: admitCount + assumptionAdmits,
            Matrics: modelOneMatrics + assumptionMatrics,
            Yield: parseFloat(modelOneMatrics + assumptionMatrics) / parseFloat(admitCount + assumptionAdmits),
            Tution: tuition
        },
        ModelTwo: {
            Admits: admitCount + assumptionAdmits,
            Matrics: modelTwoMatrics + assumptionMatrics,
            Yield: parseFloat(modelTwoMatrics + assumptionMatrics) / parseFloat(admitCount + assumptionAdmits),
            Tution: tuition
        },
        ModelThree: {
            Admits: admitCount + assumptionAdmits,
            Matrics: modelThreeMatrics + assumptionMatrics,
            Yield: parseFloat(modelThreeMatrics + assumptionMatrics) / parseFloat(admitCount + assumptionAdmits),
            Tution: tuition
        },
        Types: {
            Admits: "#",
            Matrics: "#",
            Yield: "%",
            Tution: "$"
        },
        SigFigs: {
            Admits: 0,
            Matrics: 0,
            Yield: 1,
            Tution: "$"
        }
    };

    return returnData;

}


export function CalculateBubbleChartMetrics(unitData, modelNumber, historicAssumptionData) {



    // Note that Aindex and Need Index labels must be in order of numeric value in asc. order
    let aindexLabels = historicAssumptionData.AindexLabels;
    let aindexValues = unitData.map(r => parseInt(r.Acrk)).filter((v, i, a) => a.indexOf(v) === i && v !== null).sort((a, b) => a - b).filter((v, i) => i < aindexLabels.length);
    aindexValues = Array.from({ length: Math.max(...aindexValues) - Math.min(...aindexValues) + 1 }, (v, i) => Math.min(...aindexValues) + i);
    let needIndexLabels = historicAssumptionData.NindexLabels;
    let nindexValues = unitData.map(r => parseInt(r.Ndrk)).filter((v, i, a) => a.indexOf(v) === i && v !== null).sort((a, b) => a - b).filter((v, i) => i < needIndexLabels.length);
    nindexValues = Array.from({ length: Math.max(...nindexValues) - Math.min(...nindexValues) + 1 }, (v, i) => Math.min(...nindexValues) + i);

    let appData = [];
    aindexValues.forEach(a => (nindexValues.forEach(n => appData.push({ "x": a, "y": n, "r": unitData.filter(r => parseInt(r.Acrk) === a && parseInt(r.Ndrk) === n).length }))));

    let admitData = [];
    aindexValues.forEach(a => (nindexValues.forEach(n => admitData.push({ "x": a, "y": n, "r": unitData.filter(r => parseInt(r.Acrk) === a && parseInt(r.Ndrk) === n && parseInt(r.Admit) === 1).length }))));

    let matricData = [];
    let yieldVars = ["", "ModelOneYield", "ModelTwoYield", "ModelThreeYield"]; // Empty string is placeholder, modelNum = 1, 2, 3 determines index of this array
    aindexValues.forEach(a => (nindexValues.forEach(n => matricData.push({ "x": a, "y": n, "r": Math.round(unitData.filter(r => parseInt(r.Acrk) === a && parseInt(r.Ndrk) === n && parseInt(r.Admit) === 1&& r[yieldVars[modelNumber]]!==null).map(r => parseFloat(r[yieldVars[modelNumber]])).reduce((a, b) => a + b, 0)) }))));


    let returnData = {
        Applicants: {
            verticalLabels: nindexValues,
            horizontalLabels: aindexValues,
            verticalTitle: "Need Index",
            horizontalTitle: "Academic Index",
            title: "Applicants (Base Pop)",
            data: appData
        },
        Admits: {
            verticalLabels: nindexValues,
            horizontalLabels: aindexValues,
            verticalTitle: "Need Index",
            horizontalTitle: "Academic Index",
            title: "Admits (Base Pop)",
            data: admitData
        },
        Matrics: {
            verticalLabels: nindexValues,
            horizontalLabels: aindexValues,
            verticalTitle: "Need Index",
            horizontalTitle: "Academic Index",
            title: historicAssumptionData.ModelLabels.length > 0 ? "Matrics (Base Pop Model - " + historicAssumptionData.ModelLabels[modelNumber - 1] + ")": "Matrics (Base Pop Model #" + String(modelNumber) + ")",
            data: matricData
        }
    };

    return returnData;


}



export function AddAssumptionToModeling(unitData, modelingData, hu) {

    // Returns a data structure identical to what CalculateSegmentedCals does, only it adds all assumption data to each value

    let modelingPlusAssumption = JSON.parse(JSON.stringify(modelingData));
    let tuition = parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "current_tuit_fees")[0]["HistoricAssumptionValue"]);

    // Last Year Aggs

    let lyMatricTotal = parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "matrics")[0]["HistoricAssumptionValue"]);
    let lyExtravars = Array.from({ length: hu.ExtravarLabels.length }, (v, k) => ({
        Admits: parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_matrics")[0]["HistoricAssumptionValue"]),
        Name: hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "extravar" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));
    let lyModelingMatricTotal = modelingData.Aindex.map(r => r.lyMatrics).reduce((a, b) => a + b, 0);

    // Assumption Data (last year)

    let lyAssumptionMatricTotal = Array.from({ length: hu.AindexLabels.length }, (v, k) => (parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_acrk_cat_" + String(k + 1) + "_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]))).reduce((a, b) => a + b, 0);


    // Current Year Ags

    // Assumption Group Data
    let assumptionCount = parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group_count")[0]["HistoricAssumptionValue"]);
    let assumptionData = Array.from({ length: assumptionCount }, (v, k) => ({
        Admits: parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]),
        DiscountRate: parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_discount")[0]["HistoricAssumptionValue"]),
        Name: hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));
    let assumptionMatrics = assumptionData.map(r => r.Matrics).reduce((a, b) => a + b, 0);
    let modelOneMatricTotal = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0.0);
    let modelTwoMatricTotal = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0.0);
    let modelThreeMatricTotal = unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0.0);

    // Adding Assumption to modeling data for last and this year

    // Aindex
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["lyMatrics"] = r.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_acrk_cat_" + String(i + 1) + "_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["lyYield"] = parseFloat(r.lyMatrics) / lyMatricTotal);
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelOneMatrics"] = r.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_acrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelOneYield"] = parseFloat(r.ModelOneMatrics) / (modelOneMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelTwoMatrics"] = r.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_acrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelTwoYield"] = parseFloat(r.ModelTwoMatrics) / (modelTwoMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelThreeMatrics"] = r.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_acrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Aindex.forEach((r, i) => modelingPlusAssumption.Aindex[i]["ModelThreeYield"] = parseFloat(r.ModelThreeMatrics) / (modelThreeMatricTotal + assumptionMatrics));

    // Need Index
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["lyMatrics"] = r.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_ndrk_cat_" + String(i + 1) + "_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["lyYield"] = parseFloat(r.lyMatrics) / lyMatricTotal);
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelOneMatrics"] = r.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_ndrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelOneYield"] = parseFloat(r.ModelOneMatrics) / (modelOneMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelTwoMatrics"] = r.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_ndrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelTwoYield"] = parseFloat(r.ModelTwoMatrics) / (modelTwoMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelThreeMatrics"] = r.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_ndrk_cat_" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Need.forEach((r, i) => modelingPlusAssumption.Need[i]["ModelThreeYield"] = parseFloat(r.ModelThreeMatrics) / (modelThreeMatricTotal + assumptionMatrics));

    // Extravars
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["lyMatrics"] = r.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_extravar" + String(i + 1) + "_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["lyYield"] = parseFloat(r.lyMatrics) / lyMatricTotal);
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelOneMatrics"] = r.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_extravar" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelOneYield"] = parseFloat(r.ModelOneMatrics) / (modelOneMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelTwoMatrics"] = r.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_extravar" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelTwoYield"] = parseFloat(r.ModelTwoMatrics) / (modelTwoMatricTotal + assumptionMatrics));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelThreeMatrics"] = r.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(h => h.HistoricAssumptionInputsInput === "assumption_pop_extravar" + String(i + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]));
    modelingPlusAssumption.Extravars.forEach((r, i) => modelingPlusAssumption.Extravars[i]["ModelThreeYield"] = parseFloat(r.ModelThreeMatrics) / (modelThreeMatricTotal + assumptionMatrics));

    // Demographics (male, black, hispanic/latino, soc)
    modelingPlusAssumption.Male.lyMatrics = modelingPlusAssumption.Male.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_male_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Male.lyYield = parseFloat(modelingPlusAssumption.Male.lyMatrics) / lyMatricTotal;
    modelingPlusAssumption.Male.ModelOneMatrics = modelingPlusAssumption.Male.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_male_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Male.ModelOneYield = parseFloat(modelingPlusAssumption.Male.ModelOneMatrics) / (modelOneMatricTotal + assumptionMatrics);
    modelingPlusAssumption.Male.ModelTwoMatrics = modelingPlusAssumption.Male.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_male_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Male.ModelTwoYield = parseFloat(modelingPlusAssumption.Male.ModelTwoMatrics) / (modelTwoMatricTotal + assumptionMatrics);
    modelingPlusAssumption.Male.ModelThreeMatrics = modelingPlusAssumption.Male.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_male_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Male.ModelThreeYield = parseFloat(modelingPlusAssumption.Male.ModelThreeMatrics) / (modelThreeMatricTotal + assumptionMatrics);

    modelingPlusAssumption.Black.lyMatrics = modelingPlusAssumption.Black.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_black_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Black.lyYield = parseFloat(modelingPlusAssumption.Black.lyMatrics) / lyMatricTotal;
    modelingPlusAssumption.Black.ModelOneMatrics = modelingPlusAssumption.Black.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_black_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Black.ModelOneYield = parseFloat(modelingPlusAssumption.Black.ModelOneMatrics) / (assumptionMatrics + modelOneMatricTotal);
    modelingPlusAssumption.Black.ModelTwoMatrics = modelingPlusAssumption.Black.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_black_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Black.ModelTwoYield = parseFloat(modelingPlusAssumption.Black.ModelTwoMatrics) / (assumptionMatrics + modelTwoMatricTotal);
    modelingPlusAssumption.Black.ModelThreeMatrics = modelingPlusAssumption.Black.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_black_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Black.ModelThreeYield = parseFloat(modelingPlusAssumption.Black.ModelThreeMatrics) / (assumptionMatrics + modelThreeMatricTotal);

    modelingPlusAssumption.Hispanic.lyMatrics = modelingPlusAssumption.Hispanic.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_hispanic_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Hispanic.lyYield = parseFloat(modelingPlusAssumption.Hispanic.lyMatrics) / lyMatricTotal;
    modelingPlusAssumption.Hispanic.ModelOneMatrics = modelingPlusAssumption.Hispanic.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_hispanic_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Hispanic.ModelOneYield = parseFloat(modelingPlusAssumption.Hispanic.ModelOneMatrics) / (assumptionMatrics + modelOneMatricTotal);
    modelingPlusAssumption.Hispanic.ModelTwoMatrics = modelingPlusAssumption.Hispanic.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_hispanic_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Hispanic.ModelTwoYield = parseFloat(modelingPlusAssumption.Hispanic.ModelTwoMatrics) / (assumptionMatrics + modelTwoMatricTotal);
    modelingPlusAssumption.Hispanic.ModelThreeMatrics = modelingPlusAssumption.Hispanic.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_hispanic_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Hispanic.ModelThreeYield = parseFloat(modelingPlusAssumption.Hispanic.ModelThreeMatrics) / (assumptionMatrics + modelThreeMatricTotal);

    modelingPlusAssumption.Soc.lyMatrics = modelingPlusAssumption.Soc.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_soc_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Soc.lyYield = parseFloat(modelingPlusAssumption.Soc.lyMatrics) / lyMatricTotal;
    modelingPlusAssumption.Soc.ModelOneMatrics = modelingPlusAssumption.Soc.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_soc_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Soc.ModelOneYield = parseFloat(modelingPlusAssumption.Soc.ModelOneMatrics) / (assumptionMatrics + modelOneMatricTotal);
    modelingPlusAssumption.Soc.ModelTwoMatrics = modelingPlusAssumption.Soc.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_soc_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Soc.ModelTwoYield = parseFloat(modelingPlusAssumption.Soc.ModelTwoMatrics) / (assumptionMatrics + modelTwoMatricTotal);
    modelingPlusAssumption.Soc.ModelThreeMatrics = modelingPlusAssumption.Soc.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_soc_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.Soc.ModelThreeYield = parseFloat(modelingPlusAssumption.Soc.ModelThreeMatrics) / (assumptionMatrics + modelThreeMatricTotal);

    // Academics (Score Optional, HS GPA (weighted avg), Best Composite (weighted avg))
    // Must do Best Composite first to preserve the current score optional numbers before we update them
    modelingPlusAssumption.BestComposite.lyMatricAvg = ((modelingPlusAssumption.BestComposite.lyMatricAvg * (lyModelingMatricTotal - modelingPlusAssumption.ScoreOptional.lyMatrics)) + (parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_best_comp_ly")[0]["HistoricAssumptionValue"]) * ((lyAssumptionMatricTotal - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]))))) / ((lyModelingMatricTotal - modelingPlusAssumption.ScoreOptional.lyMatrics) + (parseInt(lyAssumptionMatricTotal - hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics_ly")[0]["HistoricAssumptionValue"])));
    modelingPlusAssumption.BestComposite.ModelOneAvg = ((modelingPlusAssumption.BestComposite.ModelOneAvg * (modelOneMatricTotal - modelingPlusAssumption.ScoreOptional.ModelOneMatrics)) + (parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_best_comp")[0]["HistoricAssumptionValue"]) * ((assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]))))) / ((modelOneMatricTotal - modelingPlusAssumption.ScoreOptional.ModelOneMatrics) + (assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"])));
    modelingPlusAssumption.BestComposite.ModelTwoAvg = ((modelingPlusAssumption.BestComposite.ModelTwoAvg * (modelTwoMatricTotal - modelingPlusAssumption.ScoreOptional.ModelTwoMatrics)) + (parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_best_comp")[0]["HistoricAssumptionValue"]) * ((assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]))))) / ((modelTwoMatricTotal - modelingPlusAssumption.ScoreOptional.ModelTwoMatrics) + (assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"])));
    modelingPlusAssumption.BestComposite.ModelThreeAvg = ((modelingPlusAssumption.BestComposite.ModelThreeAvg * (modelThreeMatricTotal - modelingPlusAssumption.ScoreOptional.ModelThreeMatrics)) + (parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_best_comp")[0]["HistoricAssumptionValue"]) * ((assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]))))) / ((modelThreeMatricTotal - modelingPlusAssumption.ScoreOptional.ModelThreeMatrics) + (assumptionMatrics - parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"])));

    modelingPlusAssumption.ScoreOptional.lyMatrics = modelingPlusAssumption.ScoreOptional.lyMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics_ly")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.ScoreOptional.lyYield = parseFloat(modelingPlusAssumption.ScoreOptional.lyMatrics) / lyMatricTotal;
    modelingPlusAssumption.ScoreOptional.ModelOneMatrics = modelingPlusAssumption.ScoreOptional.ModelOneMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.ScoreOptional.ModelOneYield = parseFloat(modelingPlusAssumption.ScoreOptional.ModelOneMatrics) / (modelOneMatricTotal + assumptionMatrics);
    modelingPlusAssumption.ScoreOptional.ModelTwoMatrics = modelingPlusAssumption.ScoreOptional.ModelTwoMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.ScoreOptional.ModelTwoYield = parseFloat(modelingPlusAssumption.ScoreOptional.ModelTwoMatrics) / (modelTwoMatricTotal + assumptionMatrics);
    modelingPlusAssumption.ScoreOptional.ModelThreeMatrics = modelingPlusAssumption.ScoreOptional.ModelThreeMatrics + parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_score_optional_anticipated_matrics")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.ScoreOptional.ModelThreeYield = parseFloat(modelingPlusAssumption.ScoreOptional.ModelThreeMatrics) / (modelThreeMatricTotal + assumptionMatrics);

    modelingPlusAssumption.HsGpa.lyMatricAvg = ((modelingPlusAssumption.HsGpa.lyMatricAvg * lyModelingMatricTotal) + (parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_hsgpa_ly")[0]["HistoricAssumptionValue"]) * (lyMatricTotal - lyModelingMatricTotal))) / (lyMatricTotal);
    modelingPlusAssumption.HsGpa.ModelOneAvg = ((modelingPlusAssumption.HsGpa.ModelOneAvg * modelOneMatricTotal) + (parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_hsgpa")[0]["HistoricAssumptionValue"]) * assumptionMatrics)) / (modelOneMatricTotal + assumptionMatrics);
    modelingPlusAssumption.HsGpa.ModelTwoAvg = ((modelingPlusAssumption.HsGpa.ModelTwoAvg * modelTwoMatricTotal) + (parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_hsgpa")[0]["HistoricAssumptionValue"]) * assumptionMatrics)) / (modelTwoMatricTotal + assumptionMatrics);
    modelingPlusAssumption.HsGpa.ModelThreeAvg = ((modelingPlusAssumption.HsGpa.ModelThreeAvg * modelThreeMatricTotal) + (parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_pop_avg_hsgpa")[0]["HistoricAssumptionValue"]) * assumptionMatrics)) / (modelThreeMatricTotal + assumptionMatrics);

    // Financials (Agg. NTR (Sum Product), Discount Rate (Weighted Average))

    modelingPlusAssumption.AggregateNtr.lyMatricAvg = parseInt(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "total_agg_ntr")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.AggregateNtr.ModelOneAvg   = [(tuition - (tuition * modelingPlusAssumption.DiscountRate.ModelOneAvg)) * modelOneMatricTotal].concat(assumptionData.map(a => (tuition - (tuition * a.DiscountRate)) * a.Matrics)).reduce((a, b) => a + b, 0);
    modelingPlusAssumption.AggregateNtr.ModelTwoAvg = [(tuition - (tuition * modelingPlusAssumption.DiscountRate.ModelTwoAvg)) * modelTwoMatricTotal].concat(assumptionData.map(a => (tuition - (tuition * a.DiscountRate)) * a.Matrics)).reduce((a, b) => a + b, 0);
    modelingPlusAssumption.AggregateNtr.ModelThreeAvg = [(tuition - (tuition * modelingPlusAssumption.DiscountRate.ModelThreeAvg)) * modelThreeMatricTotal].concat(assumptionData.map(a => (tuition - (tuition * a.DiscountRate)) * a.Matrics)).reduce((a, b) => a + b, 0);


    modelingPlusAssumption.DiscountRate.lyMatricAvg = parseFloat(hu.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "total_discount")[0]["HistoricAssumptionValue"]);
    modelingPlusAssumption.DiscountRate.ModelOneAvg = assumptionData.map(r => r.Matrics * r.DiscountRate).concat([modelOneMatricTotal * modelingPlusAssumption.DiscountRate.ModelOneAvg]).reduce((a, b) => a + b) / (modelOneMatricTotal + assumptionMatrics);
    modelingPlusAssumption.DiscountRate.ModelTwoAvg = assumptionData.map(r => r.Matrics * r.DiscountRate).concat([modelTwoMatricTotal * modelingPlusAssumption.DiscountRate.ModelTwoAvg]).reduce((a, b) => a + b) / (modelTwoMatricTotal + assumptionMatrics);
    modelingPlusAssumption.DiscountRate.ModelThreeAvg = assumptionData.map(r => r.Matrics * r.DiscountRate).concat([modelThreeMatricTotal * modelingPlusAssumption.DiscountRate.ModelThreeAvg]).reduce((a, b) => a + b) / (modelThreeMatricTotal + assumptionMatrics);

    return modelingPlusAssumption;

}