import { Chart } from 'react-chartjs-2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { formatValue } from './Utils/Utils';
import "../ClassShaperRootStyles.css";
import "./CSCharts.css";
import { AddAssumptionToModeling } from './CSCalculations.js';
import {
    Chart as ChartJS,
    Legend,
    Title,
    PointElement,
    LinearScale,
    CategoryScale,
    BubbleController,
    Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // chartjs-plugin-datalabels must be loaded after the Chart.js library!




ChartJS.register(
    Legend,
    Title,
    Tooltip,
    PointElement,
    LinearScale,
    CategoryScale,
    BubbleController,
    ChartDataLabels
);


// Bubble Charts

export const SingleBubble = (props) => {
    const boundaryBuffer = 0.5;

    function formatData(dataArr) {
        let _targetData = []
        for (let i = 0; i < dataArr.length; i++) {
            let _record = dataArr[i];
            let _newRecord = { x: _record.x, y: _record.y, r: Math.sqrt(_record.r) };
            _targetData.push(_newRecord);
        };
        return _targetData;
    };

    // Chart Options Formatter

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
        },
        plugins: {
            title: {
                display: true,
                text: props.title,
                color: 'rgb(192, 0, 0)',
                font: {
                    size: 18,
                    weight: 'bold'
                }
            },
            legend: {
                display: false,
                position: 'top',
                reverse: true,
                labels: {
                    usePointStyle: true
                }
            },
            tooltip: {
                enabled: false
            },
            datalabels: {
                display: true,
                anchor: 'center',
                align: function (context) {
                    let value = context.dataset.data[context.dataIndex];
                    return 'center';
                },
                color: 'rgb(0, 0, 0)',
                font: {
                    weight: 'bold'
                },
                formatter: function (value) {
                    return props.data.filter(record => record.x === value.x && record.y === value.y)[0].r;
                }
            }
        },
        scales: {
            x: {
                display: true,
                max: Math.max(...props.horizontalLabels) + boundaryBuffer,
                min: Math.min(...props.horizontalLabels) - boundaryBuffer,
                ticks: {
                    display: true,
                    stepSize: 0.5,
                    callback: function (value) {
                        return Number.isInteger(value) ? value : ""
                    },
                    font: {
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                display: true,
                max: Math.max(...props.verticalLabels) + boundaryBuffer,
                min: Math.min(...props.verticalLabels) - boundaryBuffer,
                ticks: {
                    display: true,
                    stepSize: 0.5,
                    callback: function (value) {
                        return Number.isInteger(value) ? value : ""
                    },
                    font: {
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false
                }
            },
            x2: {
                display: true,
                min: 0,
                max: props.horizontalLabels.length,
                ticks: {
                    display: false,
                    stepSize: 1
                },
                title: {
                    display: true,
                    text: props.horizontalTitle,
                    font: {
                        weight: 'bold',
                        size: 12
                    },
                    color: 'rgb(192, 0, 0)'
                },
                grid: {
                    display: true,
                    tickLength: 0,
                    borderWidth: 0
                }
            },
            y2: {
                display: true,
                min: 0,
                max: props.verticalLabels.length,
                ticks: {
                    display: false,
                    stepSize: 1
                },
                title: {
                    display: true,
                    text: props.verticalTitle,
                    font: {
                        weight: 'bold',
                        size: 12
                    },
                    color: 'rgb(192, 0, 0)'
                },
                grid: {
                    display: true,
                    tickLength: 0,
                    borderWidth: 0
                }
            },
        }
    };

    const chartData = {
        datasets: [
            {
                data: formatData(props.data),
                backgroundColor: 'rgb(255, 235, 175)'
            }
        ]
    };

    return (
        <Container className="bubContainer">
            <Chart type='bubble' data={chartData} options={options} />
        </Container>
    );
};


// Table components

const AdmitHeaderSpacerRow = () => {
    return (
        <TableRow id="admit-header-spacer-row" className="spacer-row">
            <TableCell colSpan={1} className="admitted-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="admitted-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="admitted-metric-header"></TableCell>
        </TableRow>
    );
}

const AdmitBodySpacerRow = () => {
    return (
        <TableRow id="admit-body-spacer-row" className="spacer-row">
            <TableCell component="th" scope="row" className="admitted-metric-cell">
                { }
            </TableCell>
            <TableCell align="center" className="admitted-last-year-cell">{ }</TableCell>
            <TableCell align="center" className="admitted-last-year-cell">{ }</TableCell>
            <TableCell align="center" className="admitted-current-year-cell">{ }</TableCell>
            <TableCell align="center" className="admitted-current-year-cell">{ }</TableCell>
        </TableRow>
    );
}

const MatricHeaderSpacerRow = () => {
    return (
        <TableRow id="matric-header-spacer-row" className="spacer-row">
            <TableCell colSpan={1} className="enrolled-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="enrolled-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="enrolled-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="enrolled-metric-header"></TableCell>
            <TableCell align="center" colSpan={2} className="enrolled-metric-header"></TableCell>
        </TableRow>
    );
}


const MatricBodySpacerRow = () => {
    return (
        <TableRow id="matric-body-spacer-row" className="spacer-row">
            <TableCell component="th" scope="row" className="enrolled-metric-cell">
                { }
            </TableCell>
            <TableCell align="center" className="enrolled-last-year-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-last-year-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-one-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-one-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-two-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-two-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-three-cell">{ }</TableCell>
            <TableCell align="center" className="enrolled-model-three-cell">{ }</TableCell>
        </TableRow>
    );
}

const TotalSpacerRow = () => {
    return (
        <TableRow id="total-spacer-row">
            <TableCell align="center" className="total-cell">{}</TableCell>
            <TableCell align="center" className="total-cell">{ }</TableCell>
            <TableCell align="center" className="total-cell">{ }</TableCell>
            <TableCell align="center" className="total-cell">{ }</TableCell>
            <TableCell align="center" className="total-cell">{ }</TableCell>
        </TableRow>
    );
}



// Output Tables


export const AssumptionsTable = (props) => {

    let assumptionCount = parseInt(props.data.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group_count")[0]["HistoricAssumptionValue"]);
    let assumptionData = Array.from({ length: assumptionCount }, (v, k) => ({
        Admits: parseInt(props.data.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_admits")[0]["HistoricAssumptionValue"]),
        Matrics: parseInt(props.data.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_matrics")[0]["HistoricAssumptionValue"]),
        DiscountRate: parseFloat(props.data.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_anticipated_discount")[0]["HistoricAssumptionValue"]),
        Name: props.data.HistoricAssumptionData.filter(r => r.HistoricAssumptionInputsInput === "assumption_group" + String(k + 1) + "_name")[0]["HistoricAssumptionValue"]
    }));

    return (
        <TableContainer className="assumption-container" component={Paper}>
            <h5>Special Populations/Excluded Groups - This Year</h5>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className="assumption-name-header">Assumption Group</TableCell>
                        <TableCell align="center" className="assumption-metric-header">Admit Count</TableCell>
                        <TableCell align="center" className="assumption-metric-header">Matric Count</TableCell>
                        <TableCell align="center" className="assumption-metric-header">Discount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assumptionData.map(g => (
                        <TableRow key={g.Name}>
                            <TableCell align="center" className="assumption-rows">{g.Name}</TableCell>
                            <TableCell align="center" className="assumption-rows">{formatValue("#", g.Admits, 0)}</TableCell>
                            <TableCell align="center" className="assumption-rows">{formatValue("#", g.Matrics, 0)}</TableCell>
                            <TableCell align="center" className="assumption-rows">{formatValue("%", g.DiscountRate, 1)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export const TotalTable = (props) => {
    // Data props is only calculating admits, matrics, yield, and tuition for all unit records

    // Adding some logic to update the modeling data with current/previous year assumption data
    /*let modelingPlusAssumption = props.modelingData;*/
    let modelingPlusAssumption = AddAssumptionToModeling(props.unitData, props.modelingData, props.hu);

    return (
        <TableContainer className="total-container" component={Paper}>
            <h5>Net Totals</h5>
            <Table size="small">
                <TableHead className="total-head">
                    <TableRow>
                        <TableCell align="center" className="total-metric-header">Total</TableCell>
                        <TableCell align="center" colSpan={2} className="total-last-year-header">Last Year</TableCell>
                        <TableCell align="center" colSpan={2} className="total-model-one-header">{props.hu.ModelLabels.length > 0 ? props.hu.ModelLabels[0] : "Model #1"}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-model-two-header">{props.hu.ModelLabels.length > 1 ? props.hu.ModelLabels[1] : "Model #2"}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-model-three-header">{props.hu.ModelLabels.length > 2 ? props.hu.ModelLabels[2] : "Model #3"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(props.data.ModelOne).map((k, i) => (
                        <TableRow key={k}>
                            <TableCell align="center" className="total-cell">{k}</TableCell>
                            <TableCell align="center" colSpan={2} className="total-cell">{formatValue(props.data.Types[k], props.data.LastYear[k], props.data.SigFigs[k])}</TableCell>
                            <TableCell align="center" colSpan={2} className="total-cell">{formatValue(props.data.Types[k], props.data.ModelOne[k], props.data.SigFigs[k])}</TableCell>
                            <TableCell align="center" colSpan={2} className="total-cell">{formatValue(props.data.Types[k], props.data.ModelTwo[k], props.data.SigFigs[k])}</TableCell>
                            <TableCell align="center" colSpan={2} className="total-cell">{formatValue(props.data.Types[k], props.data.ModelThree[k], props.data.SigFigs[k])}</TableCell>
                        </TableRow>
                    ))}
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    <TableRow id="discount">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.DiscountRate.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.DiscountRate, modelingPlusAssumption.DiscountRate.lyMatricAvg, modelingPlusAssumption.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.DiscountRate, modelingPlusAssumption.DiscountRate.ModelOneAvg, modelingPlusAssumption.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.DiscountRate, modelingPlusAssumption.DiscountRate.ModelTwoAvg, modelingPlusAssumption.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.DiscountRate, modelingPlusAssumption.DiscountRate.ModelThreeAvg, modelingPlusAssumption.SigFigs.DiscountRate)}</TableCell>
                    </TableRow>
                    <TableRow id="aggntr">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.AggregateNtr.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.AggregateNtr, modelingPlusAssumption.AggregateNtr.lyMatricAvg, modelingPlusAssumption.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.AggregateNtr, modelingPlusAssumption.AggregateNtr.ModelOneAvg, modelingPlusAssumption.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.AggregateNtr, modelingPlusAssumption.AggregateNtr.ModelTwoAvg, modelingPlusAssumption.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.AggregateNtr, modelingPlusAssumption.AggregateNtr.ModelThreeAvg, modelingPlusAssumption.SigFigs.AggregateNtr)}</TableCell>
                    </TableRow>
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    {modelingPlusAssumption.Aindex.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" align="center" className="total-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    <TableRow id="best_comp">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            Best Composite SAT/ACT
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{String(parseInt(modelingPlusAssumption.BestComposite.lyMatricAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{String(parseInt(modelingPlusAssumption.BestComposite.ModelOneAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{String(parseInt(modelingPlusAssumption.BestComposite.ModelTwoAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{String(parseInt(modelingPlusAssumption.BestComposite.ModelThreeAvg))}</TableCell>
                    </TableRow>
                    <TableRow id="hsgpa">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            High School GPA
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.HsGpa, modelingPlusAssumption.HsGpa.lyMatricAvg, modelingPlusAssumption.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.HsGpa, modelingPlusAssumption.HsGpa.ModelOneAvg, modelingPlusAssumption.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.HsGpa, modelingPlusAssumption.HsGpa.ModelTwoAvg, modelingPlusAssumption.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="total-cell">{formatValue(modelingPlusAssumption.Types.HsGpa, modelingPlusAssumption.HsGpa.ModelThreeAvg, modelingPlusAssumption.SigFigs.HsGpa)}</TableCell>
                    </TableRow>
                    <TableRow id="score_optional">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.ScoreOptional.Label}
                        </TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.ScoreOptional.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.ScoreOptional.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.ScoreOptional.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.ScoreOptional.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.ScoreOptional.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.ScoreOptional.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.ScoreOptional.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.ScoreOptional.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    <TableRow id="male">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.Male.Label}
                        </TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Male.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Male.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Male.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Male.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Male.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Male.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Male.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Male.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="black">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.Black.Label}
                        </TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Black.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Black.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Black.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Black.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Black.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Black.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Black.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Black.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="hispanic">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.Hispanic.Label}
                        </TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Hispanic.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Hispanic.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Hispanic.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Hispanic.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Hispanic.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Hispanic.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Hispanic.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Hispanic.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="soc">
                        <TableCell component="th" scope="row" align="center" className="total-cell">
                            {modelingPlusAssumption.Soc.Label}
                        </TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Soc.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Soc.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Soc.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Soc.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Soc.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Soc.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("#", modelingPlusAssumption.Soc.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="total-cell">{formatValue("%", modelingPlusAssumption.Soc.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    {modelingPlusAssumption.Need.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" align="center" className="total-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <TotalSpacerRow />
                    <TotalSpacerRow />
                    {modelingPlusAssumption.Extravars.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" align="center" className="total-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="total-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}



export const AdmitTable = (props) => {

    return (
        <TableContainer className="admitted-table-container" component={Paper}>
            <h5>Admitted Population</h5>
            <Table size="small">
                <TableHead>
                    <TableRow id="admit-placeholder-header-1" className="admitted-hidden-row">
                        <TableCell colSpan={1} className="admitted-metric-header"></TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-metric-header">Admits</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-metric-header">Admits</TableCell>
                    </TableRow>
                    <AdmitHeaderSpacerRow />
                    <TableRow>
                        <TableCell colSpan={1} style={{color: "rgb(84, 130, 53)", fontStyle: "italic", fontWeight: "bold", borderBottom: "0px"}}>Admits without Yield: {formatValue("#", props.totalAdmits - props.data.Aindex.map(r => r.Admits).reduce((a, b) => a + b), 0)}</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-last-year-header">Last Year</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-current-year-header">Admits for Model</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="admitted-metric-header">Admits</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-last-year-header">{formatValue("#", props.data.Aindex.map(r => r.lyAdmits).reduce((a, b) => a + b), 0)}</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-current-year-header">{formatValue("#", props.data.Aindex.map(r => r.Admits).reduce((a, b) => a + b), 0)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow id="discount-placeholder">
                        <TableCell component="th" scope="row" className="admitted-metric-cell-hidden">
                            {props.data.DiscountRate.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-last-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-last-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-current-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-current-year-cell"></TableCell>
                    </TableRow>
                    <TableRow id="aggntr-placeholder">
                        <TableCell component="th" scope="row" className="admitted-metric-cell-hidden">
                            {props.data.AggregateNtr.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-last-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-last-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-current-year-cell"></TableCell>
                        <TableCell align="center" colSpan={1} className="admitted-current-year-cell"></TableCell>
                    </TableRow>
                    <AdmitBodySpacerRow />
                    {props.data.Aindex.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="admitted-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", row.lyAdmits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", row.lyAdmitPerc, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", row.Admits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", row.AdmitPerc, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <AdmitBodySpacerRow />
                    <TableRow id="best_comp">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.BestComposite.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-last-year-cell">{String(parseInt(props.data.BestComposite.lyAdmitAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-current-year-cell">{isNaN(props.data.BestComposite.AdmitAvg)? "#": String(parseInt(props.data.BestComposite.AdmitAvg))}</TableCell>
                    </TableRow>
                    <TableRow id="hsgpa">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.HsGpa.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-last-year-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.lyAdmitAvg, props.data.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="admitted-current-year-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.AdmitAvg, props.data.SigFigs.HsGpa)}</TableCell>
                    </TableRow>
                    <TableRow id="score_optional">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.ScoreOptional.Label}
                        </TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", props.data.ScoreOptional.lyAdmits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", props.data.ScoreOptional.lyAdmitPerc, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", props.data.ScoreOptional.Admits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", props.data.ScoreOptional.AdmitPerc, 0)}</TableCell>
                    </TableRow>
                    <AdmitBodySpacerRow />
                    <TableRow id="male">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.Male.Label}
                        </TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", props.data.Male.lyAdmits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", props.data.Male.lyAdmitPerc, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", props.data.Male.Admits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", props.data.Male.AdmitPerc, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="black">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.Black.Label}
                        </TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", props.data.Black.lyAdmits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", props.data.Black.lyAdmitPerc, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", props.data.Black.Admits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", props.data.Black.AdmitPerc, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="hispanic">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.Hispanic.Label}
                        </TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", props.data.Hispanic.lyAdmits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", props.data.Hispanic.lyAdmitPerc, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", props.data.Hispanic.Admits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", props.data.Hispanic.AdmitPerc, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="soc">
                        <TableCell component="th" scope="row" className="admitted-metric-cell">
                            {props.data.Soc.Label}
                        </TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", props.data.Soc.lyAdmits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", props.data.Soc.lyAdmitPerc, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", props.data.Soc.Admits, 0)}</TableCell>
                        <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", props.data.Soc.AdmitPerc, 0)}</TableCell>
                    </TableRow>
                    <AdmitBodySpacerRow />
                    {props.data.Need.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="admitted-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", row.lyAdmits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", row.lyAdmitPerc, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", row.Admits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", row.AdmitPerc, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <AdmitBodySpacerRow />
                    {props.data.Extravars.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="admitted-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("#", row.lyAdmits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-last-year-cell">{formatValue("%", row.lyAdmitPerc, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("#", row.Admits, 0)}</TableCell>
                            <TableCell align="center" className="admitted-current-year-cell">{formatValue("%", row.AdmitPerc, 0)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export const PredictedMatricTable = (props) => {


    return (
        <TableContainer className="enrolled-table-container" component={Paper}>
            <h5>Enrolled Population</h5>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={1} className="enrolled-metric-header"></TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-header">Actual Matrics</TableCell>
                        <TableCell align="center" colSpan={6} className="enrolled-current-year-title-header">Predicted Matrics</TableCell>
                    </TableRow>
                    <MatricHeaderSpacerRow />
                    <TableRow>
                        <TableCell colSpan={1} className="enrolled-metric-header"></TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-header">Last Year</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-one-header">{props.historic.ModelLabels.length > 0 ? props.historic.ModelLabels[0] : "Model #1"}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-two-header">{props.historic.ModelLabels.length > 1 ? props.historic.ModelLabels[1] : "Model #2"}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-three-header">{props.historic.ModelLabels.length > 2 ? props.historic.ModelLabels[2] : "Model #3"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="enrolled-metric-header">Matrics/Yield</TableCell>
                        <TableCell align="center" className="enrolled-last-year-header">{formatValue("#", props.data.Aindex.map(r => r.lyMatrics).reduce((a, b) => a + b), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-header">{formatValue("%", props.data.Aindex.map(r => r.lyMatrics).reduce((a, b) => a + b) / props.data.Aindex.map(r => r.lyAdmits).reduce((a, b) => a + b), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-header">{formatValue("#", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-header">{formatValue("%", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => parseFloat(r.ModelOneYield)).reduce((a, b) => a + b, 0.0) / props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelOneYield).map(r => parseFloat(r.Admit)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-header">{formatValue("#", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-header">{formatValue("%", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => parseFloat(r.ModelTwoYield)).reduce((a, b) => a + b, 0.0) / props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelTwoYield).map(r => parseFloat(r.Admit)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-header">{formatValue("#", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-header">{formatValue("%", props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => parseFloat(r.ModelThreeYield)).reduce((a, b) => a + b, 0.0) / props.unitData.filter(r => parseInt(r.Admit) === 1 && r.ModelThreeYield).map(r => parseFloat(r.Admit)).reduce((a, b) => a + b, 0.0), 1)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow id="discount">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.DiscountRate.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-cell">{formatValue(props.data.Types.DiscountRate, props.data.DiscountRate.lyMatricAvg, props.data.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-one-cell">{formatValue(props.data.Types.DiscountRate, props.data.DiscountRate.ModelOneAvg, props.data.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-two-cell">{formatValue(props.data.Types.DiscountRate, props.data.DiscountRate.ModelTwoAvg, props.data.SigFigs.DiscountRate)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-three-cell">{formatValue(props.data.Types.DiscountRate, props.data.DiscountRate.ModelThreeAvg, props.data.SigFigs.DiscountRate)}</TableCell>
                    </TableRow>
                    <TableRow id="aggntr">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.AggregateNtr.Label}
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-cell">{formatValue(props.data.Types.AggregateNtr, props.data.AggregateNtr.lyMatricAvg, props.data.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-one-cell">{formatValue(props.data.Types.AggregateNtr, props.data.AggregateNtr.ModelOneAvg, props.data.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-two-cell">{formatValue(props.data.Types.AggregateNtr, props.data.AggregateNtr.ModelTwoAvg, props.data.SigFigs.AggregateNtr)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-three-cell">{formatValue(props.data.Types.AggregateNtr, props.data.AggregateNtr.ModelThreeAvg, props.data.SigFigs.AggregateNtr)}</TableCell>
                    </TableRow>
                    <MatricBodySpacerRow />
                    {props.data.Aindex.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="enrolled-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <MatricBodySpacerRow />
                    <TableRow id="best_comp">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            Best Composite SAT/ACT
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-cell">{String(parseInt(props.data.BestComposite.lyMatricAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-one-cell">{isNaN(props.data.BestComposite.ModelOneAvg) ? "#" : String(parseInt(props.data.BestComposite.ModelOneAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-two-cell">{isNaN(props.data.BestComposite.ModelTwoAvg) ? "#" : String(parseInt(props.data.BestComposite.ModelTwoAvg))}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-three-cell">{isNaN(props.data.BestComposite.ModelThreeAvg) ? "#" : String(parseInt(props.data.BestComposite.ModelThreeAvg))}</TableCell>
                    </TableRow>
                    <TableRow id="hsgpa">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            High School GPA
                        </TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-last-year-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.lyMatricAvg, props.data.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-one-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.ModelOneAvg, props.data.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-two-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.ModelTwoAvg, props.data.SigFigs.HsGpa)}</TableCell>
                        <TableCell align="center" colSpan={2} className="enrolled-model-three-cell">{formatValue(props.data.Types.HsGpa, props.data.HsGpa.ModelThreeAvg, props.data.SigFigs.HsGpa)}</TableCell>
                    </TableRow>
                    <TableRow id="score_optional">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.ScoreOptional.Label}
                        </TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", props.data.ScoreOptional.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", props.data.ScoreOptional.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", props.data.ScoreOptional.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", props.data.ScoreOptional.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", props.data.ScoreOptional.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", props.data.ScoreOptional.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", props.data.ScoreOptional.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", props.data.ScoreOptional.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <MatricBodySpacerRow />
                    <TableRow id="male">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.Male.Label}
                        </TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", props.data.Male.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", props.data.Male.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", props.data.Male.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", props.data.Male.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", props.data.Male.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", props.data.Male.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", props.data.Male.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", props.data.Male.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="black">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.Black.Label}
                        </TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", props.data.Black.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", props.data.Black.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", props.data.Black.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", props.data.Black.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", props.data.Black.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", props.data.Black.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", props.data.Black.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", props.data.Black.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="hispanic">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.Hispanic.Label}
                        </TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", props.data.Hispanic.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", props.data.Hispanic.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", props.data.Hispanic.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", props.data.Hispanic.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", props.data.Hispanic.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", props.data.Hispanic.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", props.data.Hispanic.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", props.data.Hispanic.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <TableRow id="soc">
                        <TableCell component="th" scope="row" className="enrolled-metric-cell">
                            {props.data.Soc.Label}
                        </TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", props.data.Soc.lyMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", props.data.Soc.lyYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", props.data.Soc.ModelOneMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", props.data.Soc.ModelOneYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", props.data.Soc.ModelTwoMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", props.data.Soc.ModelTwoYield, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", props.data.Soc.ModelThreeMatrics, 0)}</TableCell>
                        <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", props.data.Soc.ModelThreeYield, 0)}</TableCell>
                    </TableRow>
                    <MatricBodySpacerRow />
                    {props.data.Need.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="enrolled-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                    <MatricBodySpacerRow />
                    {props.data.Extravars.map((row) => (
                        <TableRow
                            key={row.Label}
                        >
                            <TableCell component="th" scope="row" className="enrolled-metric-cell">
                                {row.Label}
                            </TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("#", row.lyMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-last-year-cell">{formatValue("%", row.lyYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("#", row.ModelOneMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-one-cell">{formatValue("%", row.ModelOneYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("#", row.ModelTwoMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-two-cell">{formatValue("%", row.ModelTwoYield, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("#", row.ModelThreeMatrics, 0)}</TableCell>
                            <TableCell align="center" className="enrolled-model-three-cell">{formatValue("%", row.ModelThreeYield, 0)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}