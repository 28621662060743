import React, { useRef, useState } from 'react';
import "./StepperFormUtils.css";
import {
    Container,
    Autocomplete,
    TextField,
    InputLabel,
    Grid,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    Typography,
    Divider
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';


// CUSTOM DEFINED FORM INPUTS USING MATERIAL UI AS A BASE


const SingleSelect = (props) => {

    return (
        <FormControl style={{ width: "350px", margin: "10px" }}>
            <InputLabel>{props.label}</InputLabel>
            <Select value={props.currValue} name={String(props.idNum)} input={<OutlinedInput required={props.required === 1} label={props.label} />} onChange={(event) => props.parentCB(event)}>
                {props.choices.split("/").map(c => (
                    <MenuItem key={c} value={c}>{c}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}


const TextInput = (props) => {

    return (
        <FormControl style={{ width: "450px", margin: "10px" }}>
            <TextField disabled={props.disabled === 1} defaultValue={props.defValue} id={String(props.idNum)} required={props.required === 1} onChange={(event) => props.parentCB(event)} fullWidth label={props.label} variant="outlined" helperText={props.notes} />
        </FormControl>
    );
}

const MultiLineTextInput = (props) => {
    return (
        <FormControl style={{ width: "450px", margin: "10px" }}>
            <TextField multiline minRows={5} disabled={props.disabled === 1} defaultValue={props.defValue} id={String(props.idNum)} required={props.required === 1} onChange={(event) => props.parentCB(event)} fullWidth label={props.label} variant="outlined" helperText={props.notes} />
        </FormControl>
    );
}

const ImageInput = (props) => {

    const fileInputRef = useRef();

    // Event Handlers

    const selectFile = (event) => {
        event.preventDefault();
        console.log("Selecting file: " + event.target.files[0].name);
        props.parentCB(event);
    }

    const dropFile = (event) => {
        event.preventDefault();
        console.log("Selecting file: " + event.dataTransfer.files[0].name);
        props.parentCB(event);
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    const dragEnter = (event) => {
        event.preventDefault();
    }

    const dragLeave = (event) => {
        event.preventDefault();
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    return (
        <div className='dropArea' onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={dropFile} onClick={fileInputClicked}>
            <Grid direction="column">
                <Grid direction="row" className='uploadIcon' />
                <Grid direction="row" className='dropAreaMessage'>
                    {props.notes}
                    <FileUploadIcon />
                    <input id={String(props.idNum)} className='fileClick' type='file' ref={fileInputRef} onChange={selectFile} />
                </Grid>
            </Grid>
        </div>
    );
}

// GENERALIZED STEPPER PAGE TEMPLATE

export const PageTemplate = (props) => {

    const subSections = props.formData.map(r => String(r.SubSection)).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => parseInt(a) - parseInt(b));
    const sectionedFormData = subSections.map(s => props.formData.filter(r => String(r.SubSection) === s));

    return (
        <Grid container direction="column" spacing={4}>
            {sectionedFormData.map(s => (
                <Grid key={"subsection_" + String(s[0].SubSection) + "_grid"} item>
                    <Grid container direction="column" style={{ padding: "20px", border: "1px solid gray", borderRadius: "25px" }} spacing={1}>
                        <Typography variant="subtitle1">{s[0]["SubSectionNotes"]}</Typography>
                        <Divider style={{ marginBottom: "40px" }} />
                        {s.map(r => (
                            <Grid key={r.Input + "_grid"} item xs={2}>
                                {r.isHidden || r.Disabled===1 ? null : componentSelector(r, props.CurrentInputs)}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}


// MAIN COMPONENT SELECTOR OBJECT THAT CORRESPONDS TO THE 'TYPE' FIELD IN METADATA

export const componentSelector = (input, currValues) => {

    switch (input.Type) {
        case "FreeText":
            return <TextInput idNum={input.Id} defValue={Object.keys(currValues).includes(String(input.Id)) ? currValues[input.Id] :input.DefaultValue} disabled={input.Disabled} input={input.Input} label={input.Label} notes={input.Notes} required={input.Required} parentCB={input.Callback} />;
        case "Select":
            return <SingleSelect idNum={input.Id} currValue={Object.keys(currValues).includes(String(input.Id))? currValues[input.Id]: ""} defValue={input.DefaultValue} input={input.Input} label={input.Label} choices={input.Choices} notes={input.Notes} required={input.Required} parentCB={input.Callback} />
        case "Image":
            return <ImageInput idNum={input.Id} input={input.Input} label={input.Label} notes={input.Notes} parentCB={input.Callback} />
        case "MultiLineText":
            return <MultiLineTextInput idNum={input.Id} defValue={input.DefaultValue} disabled={input.Disabled} input={input.Input} label={input.Label} notes={input.Notes} required={input.Required} parentCB={input.Callback} />;
        default:
            console.error("Unable to parse type: " + String(input));
    }
}