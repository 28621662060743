import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import TableViewIcon from '@mui/icons-material/TableView';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { LoaderContainer } from './Utils/Utils';
import { CalculateSegmentedMetrics, CalculateTotalMetrics, CalculateBubbleChartMetrics } from './CSCalculations';
import "./Forms.css";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';

// Save Scenario Form

export const SaveForm = (props) => {

    const [value, setValue] = useState('');
    const [saving, setSaving] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const saveScenario = async (event) => {
        event.preventDefault();
        setShowAlert(false);
        setSaving(true);
        console.debug("Saving scenario...");
        try {
            let jsonPayload = JSON.stringify(props.scenarioData);
            jsonPayload = jsonPayload.replace(/'/g, "~"); // replace apostrophes on a global scale for transit
            jsonPayload = jsonPayload.replace(/"/g, "'"); // replace double quotes on a global scale with single quotes for transit
            jsonPayload = "\"" + jsonPayload + "\""; // Surround entire json string with double quotes
            let reqOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json', "t": props.auth, "d": value },
                body: jsonPayload
            };
            let response = await fetch(process.env.REACT_APP_CS_SAVE_SCENARIO, reqOptions);
            console.log("scenario saved");
            console.log(response);
            setAlertMessage("Success saving scenario!");
        }
        catch (exception_var) {
            console.log("Error saving scenario");
            console.log(exception_var);
            setAlertMessage("Error saving scenario! " + exception_var);
            setAlertSeverity("error");
        }
        finally {
            setShowAlert(true);
            setSaving(false);
        }
    }

    return (
        <Modal
            open={true}
            onClose={props.closeMethod}
        >
            <Container className="scenarioFormContainer">
                <h4 className="scenarioFormHeader">Save Scenario</h4>
                <FormControl>
                    <Stack direction="column" spacing={3}>
                        <TextField
                            id="saveScenarioDescriptionInput"
                            label="Description"
                            multiline
                            minRows={4}
                            maxRows={6}
                            value={value}
                            onChange={handleChange}
                            className="scenarioFormTextBox"
                        />
                        <Stack direction="row" spacing={2}>
                            <Button
                                disabled={saving || value.replace(" ", "")===""}
                                onClick={saveScenario}
                                startIcon={saving? null: <SaveIcon />}
                                variant="outlined"
                                color="primary"
                            >
                                {saving ? <CircularProgress size="25px" color="inherit" /> : "Save"}
                            </Button>
                            <Alert hidden={!showAlert} severity={alertSeverity} className="formAlert">{alertMessage}</Alert>
                        </Stack>
                    </Stack>
                </FormControl>
            </Container>
        </Modal>
    );
}


// Load Scenario Form

export const LoadForm = (props) => {

    const [scenarios, setScenarios] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    // Effect to be ran after initial render
    useEffect(() => {
        const getScenarios = async () => {
            console.debug("Scenarios superset...");
            let reqOptions = {
                method: "GET",
                headers: {
                    "t": props.auth
                }
            };
            let response = await fetch(process.env.REACT_APP_CS_GET_ALL_SCENARIOS, reqOptions);
            let data = await response.json();
            let jsonData = JSON.parse(data).ssp;
            console.debug("Scenarios obtained.");
            setScenarios(jsonData);
        };
        getScenarios();

        return () => {
            console.debug("Performing load form cleanup...");
            setScenarios([]);
            setValue([]);
            console.debug("Load form cleanup complete.");
        }

    }, []);


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const loadScenario = async (event) => {
        event.preventDefault();
        setLoading(true);
        console.debug("Loading scenario...");

        // Send data via request to backend api
        try {
            let reqOptions = {
                method: "GET",
                headers: {
                    'ScenarioId': value,
                    "t": props.auth
                }
            };
            let response = await fetch(process.env.REACT_APP_CS_LOAD_SCENARIO, reqOptions);
            let data = await response.json();
            let scenarioData = JSON.parse(data);
            console.debug(scenarioData);

            // updating unit data
            let unitDataCopy = JSON.parse(JSON.stringify(props.unitData)); // Destroying reference to parent state
            let scenarioIds = scenarioData.map(r => r.UnitId);
            for (let i = 0; i < unitDataCopy.length; i++) {
                let record = unitDataCopy[i];
                if (scenarioIds.includes(record.StudentId)) {
                    let admitValue = scenarioData.filter(r => r.UnitId === record.StudentId)[0]["Admit"];
                    record.Admit = admitValue ? 1 : 0;
                }
            }

            props.updateUnitMethod(unitDataCopy);

            let segmentedCalcs = CalculateSegmentedMetrics(unitDataCopy, props.historicAssumptionData);
            props.updateSegMethod(segmentedCalcs);

            let totalCalcs = CalculateTotalMetrics(unitDataCopy, props.historicAssumptionData, segmentedCalcs);
            props.updateTotalMethod(totalCalcs);

            let _bubData = CalculateBubbleChartMetrics(unitDataCopy, props.modelNum, props.historicAssumptionData);
            props.updateBubMethod(_bubData);

            let _scenario = unitDataCopy.map(r => ({ UnitId: r.StudentId, Admit: r.Admit }));
            props.updateScenarioMethod(_scenario);

            let _selected = new Set(unitDataCopy.filter(r => r.Admit === 1).map(r => r.id));
            props.updateSelectionsMethod(Array.from(_selected));

            console.debug("scenario loaded");
            setAlertMessage("Success loading scenario!");
        }
        catch (exception_var) {
            console.log("Error loading scenario");
            console.log(exception_var);
            setAlertMessage("Error loading scenario! " + exception_var);
            setAlertSeverity("error");
        }
        finally {
            setShowAlert(true);
            setLoading(false);
        }
    }

    return (
        <Modal
            open={true}
            onClose={props.closeMethod}
        >
            <Container className="scenarioFormContainer">
                <h4 className="scenarioFormHeader">Load Scenario</h4>
                <FormControl>
                    <InputLabel id="scenario-label">Scenario</InputLabel>
                    <Stack direction="column" spacing={3}>
                        <Select
                            labelId="scenario-label"
                            id="scenario-select"
                            disabled={scenarios.length===0}
                            value={value}
                            label={scenarios.length===0? "No saved scenarios available to load": "Scenarios"}
                            onChange={handleChange}
                            className="scenarioFormSelect"
                        >
                            {scenarios.map(s => (
                                <MenuItem value={s.Id} key={s.Id}>{s.SaveDate}-{s.Description}</MenuItem>
                            ))}
                        </Select>
                        <Stack direction="row" spacing={2}>
                            <Button
                                disabled={loading || value.replace(" ", "") === ""}
                                onClick={loadScenario}
                                startIcon={loading ? null : <TableViewIcon />}
                                variant="outlined"
                                color="primary"
                            >
                                {loading ? <CircularProgress size="25px" color="inherit" /> : "Load"}
                            </Button>
                            <Alert hidden={!showAlert} severity={alertSeverity} className="formAlert">{alertMessage}</Alert>
                        </Stack>
                    </Stack>
                </FormControl>
            </Container>
        </Modal>
    );
}


// Upload Form

export const UploadForm = (props) => {


    const fileInputRef = useRef();
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [uploading, setUploading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    // Event Handlers

    const selectFile = (event) => {
        event.preventDefault();
        console.debug("Selecting file: " + event.target.files[0].name);
        setFile(event.target.files[0]);
    }

    const dropFile = (event) => {
        event.preventDefault();
        console.debug("Selecting file: " + event.dataTransfer.files[0].name);
        setFile(event.dataTransfer.files[0]);
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    const dragEnter = (event) => {
        event.preventDefault();
    }

    const dragLeave = (event) => {
        event.preventDefault();
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleChange = (event) => {
        setDescription(event.target.value);
    };

    const uploadFile = async (event) => {
        event.preventDefault();
        setShowAlert(false);
        setUploading(true);
        console.debug("Uploading file " + file.name);

        let formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        );

        // Send form data via request to backend api
        try {
            let response = await axios.post(process.env.REACT_APP_CS_UPLOAD_FILE, formData, {
                headers: {
                    't': props.auth,
                    'c': props.client
                }
            });
            console.log("File uploaded");
            console.log(response);
            setAlertMessage("Success uploading file!");
        }
        catch (exception_var) {
            console.log("Error uploading file");
            console.log(exception_var);
            setAlertMessage("Error uploading file! " + exception_var);
            setAlertSeverity("error");
        }
        finally {
            setShowAlert(true);
            setUploading(false);
        }

    }

    return (

        <Container className="query_upload_FormContainer">
            <h4 style={{marginBottom: "45px"}}>Upload File</h4>
            <FormControl>
                <Stack direction="column" spacing={1}>
                    <div
                        className="uploadFormDropArea"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={dropFile}
                        onClick={fileInputClicked}
                    >
                        <Stack direction="column">
                            <FileUploadIcon />
                            <input id="upload-file-input" className="uploadFormFileInput" type='file' ref={fileInputRef} onChange={selectFile} />
                        </Stack>
                    </div>
                    {file ? <Alert severity="info" className="formAlert">{file.name}</Alert>: null}
                    <TextField
                        id="uploadFileDescriptionInput"
                        label="Description"
                        multiline
                        maxRows={1}
                        value={description}
                        onChange={handleChange}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            disabled={uploading || description === "" || file === null}
                            onClick={uploadFile}
                            startIcon={uploading ? null : <FileUploadIcon />}
                            variant="outlined"
                            color="primary"
                            className="uploadFormButton"
                        >
                            {uploading ? <CircularProgress size="25px" color="inherit" /> : "Upload"}
                        </Button>
                        <Alert hidden={!showAlert} severity={alertSeverity} className="formAlert">{alertMessage}</Alert>
                    </Stack>
                </Stack>
            </FormControl>
        </Container>
    );
}


// Source Query Update Form

export const QueryUpdater = (props) => {


    // State components and other constants
    const [superset, setSuperset] = useState([]);
    const [checked, setChecked] = React.useState([]);
    const [choices, setChoices] = React.useState([]);
    const [chosen, setChosen] = React.useState([]);
    const [updating, setUpdating] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    const choicesChecked = intersection(checked, choices);
    const chosenChecked = intersection(checked, chosen);

    // Effect to ran when component is instantiated
    useEffect(() => {
        const getFields = async () => {
            console.debug("Obtaining field superset and currently chosen fields...");
            let reqOptions = {
                method: "GET",
                headers: {
                    "c": props.client,
                    "t": props.auth
                }
            };
            let response = await fetch(process.env.REACT_APP_CS_GET_QUERY_FIELD_SUPERSET, reqOptions);
            let data = await response.json();
            let jsonData = JSON.parse(data);

            let chosenResp = await fetch(process.env.REACT_APP_CS_GET_CLIENT_QUERY_FIELDS, reqOptions);
            let chosenData = await chosenResp.json();
            chosenData = JSON.parse(chosenData);
            chosenData = chosenData.Qfpl.map(r => r.SupplementalField); // Only want fields
            console.debug("Field superset and currently chosen fields obtained.");

            setChosen(chosenData);
            setChoices(jsonData.filter(v => !chosenData.includes(v)));
            setInitialLoadComplete(true);
        };
        getFields();

        return () => {
            console.debug("Performing save form cleanup...");
            setSuperset([]);
            setChecked([]);
            setChoices([]);
            setChosen([]);
            console.debug("Save form cleanup complete.");
        }

    }, []);

    // Click handlers

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllChosen = () => {
        setChosen(chosen.concat(choices));
        setChoices([]);
    };

    const handleCheckedChosen = () => {
        setChosen(chosen.concat(choicesChecked));
        setChoices(not(choices, choicesChecked));
        setChecked(not(checked, choicesChecked));
    };

    const handleCheckedChoices = () => {
        setChoices(choices.concat(chosenChecked));
        setChosen(not(chosen, chosenChecked));
        setChecked(not(checked, chosenChecked));
    };

    const handleAllChoices = () => {
        setChoices(choices.concat(chosen));
        setChosen([]);
    };

    const updateQuery = async (event) => {
        event.preventDefault();
        setShowAlert(false);
        setUpdating(true);
        console.debug("Updating source query...");
        try {
            let jsonPayload = JSON.stringify(chosen);
            jsonPayload = jsonPayload.replace(/'/g, "~"); // replace apostrophes on a global scale for transit
            jsonPayload = jsonPayload.replace(/"/g, "'"); // replace double quotes on a global scale with single quotes for transit
            jsonPayload = "\"" + jsonPayload + "\""; // Surround entire json string with double quotes
            let reqOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', "t": props.auth, "c": props.client },
                body: jsonPayload
            };
            let response = await fetch(process.env.REACT_APP_CS_UPDATE_QUERY_FIELDS, reqOptions);
            console.log("query updated");
            console.log(response);
            setAlertMessage("Success updating source query!");
        }
        catch (exception_var) {
            console.log("Error updating source query");
            console.log(exception_var);
            setAlertMessage("Error updating source query! " + exception_var);
            setAlertSeverity("error");
        }
        finally {
            setShowAlert(true);
            setUpdating(false);
        }
    }

    // Other Utility methods
    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    // List Component

    const customList = (items, listTitle) => (
        <div>
            <h6>{listTitle}</h6>
            <Paper className="queryUpdaterFieldContainer">
                <List dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;

                        return (
                            <ListItem
                                key={value}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
        </div>
    );

    return (
        <LoaderContainer showChild={initialLoadComplete}>
            <Container className="query_upload_FormContainer">
                <h4 className="queryUpdaterFormHeader">Update Source Query</h4>
                <FormControl>
                    <Stack direction="column" spacing={3}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item>{customList(choices, "Field Choices")}</Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            className="queryUpdaterFormButton"
                                            variant="outlined"
                                            size="small"
                                            onClick={handleAllChosen}
                                            disabled={choices.length === 0}
                                            aria-label="move all to chosen"
                                        >
                                            ≫
                                        </Button>
                                        <Button
                                            className="queryUpdaterFormButton"
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedChosen}
                                            disabled={choicesChecked.length === 0}
                                            aria-label="move selected to chosen"
                                        >
                                            &gt;
                                        </Button>
                                        <Button
                                            className="queryUpdaterFormButton"
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedChoices}
                                            disabled={chosenChecked.length === 0}
                                            aria-label="move selected to choices"
                                        >
                                            &lt;
                                        </Button>
                                        <Button
                                            className="queryUpdaterFormButton"
                                            variant="outlined"
                                            size="small"
                                            onClick={handleAllChoices}
                                            disabled={chosen.length === 0}
                                            aria-label="move all to choices"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                            <Grid item>{customList(chosen, "Fields Chosen")}</Grid>
                        </Grid>
                        <Stack direction="row" spacing={2}>
                            <Button
                                disabled={updating}
                                onClick={updateQuery}
                                startIcon={updating? null: <SaveIcon />}
                                variant="outlined"
                                color="primary"
                            >
                                {updating ? <CircularProgress size="25px" color="inherit" /> : "Save"}
                            </Button>
                            <Alert hidden={!showAlert} severity={alertSeverity} className="formAlert">{alertMessage}</Alert>
                        </Stack>
                    </Stack>
                </FormControl>
            </Container>
        </LoaderContainer>
    );
}



// Student ID Input Text Box

export const StudentInputForm = (props) => {

    // Component State
    const [visible, setVisible] = useState(false);

    // Event Handlers

    const handleShow = (event) => {
        setVisible(event.target.checked);
    }

    const handlePaste = (event) => {
        let pasted = String(event.target.value).split("\n").filter(i => i !== "");
        if (pasted.length > 0) {
            let gridIds = props.unitData.filter(r => pasted.includes(r.StudentId)).map(r => r.id);
            if (gridIds.length > 0) {
                props.handleSelectMethod(gridIds);
            }
        }
    }

    return (
        <FormControl fullWidth component="fieldset" variant="standard">
            <FormGroup>
                <Stack direction="column" spacing={3}>
                    <Stack direction="column" spacing={1}>
                        <div><FormLabel>Enable Bulk Admit</FormLabel> <Tooltip title="Enabling bulk admits will override any existing filters set above."><HelpOutlineOutlinedIcon></HelpOutlineOutlinedIcon ></Tooltip></div>
                        <Switch checked={visible} onChange={handleShow} name="gilad" />
                    </Stack>
                    {visible ? <TextField
                        fullWidth
                        id="student_input_box"
                        label="Paste Student IDs (Line Separated)"
                        multiline
                        onChange={handlePaste}
                        minRows={10}
                        maxRows={15}
                    /> : null}
                </Stack>
            </FormGroup>
        </FormControl>
    );
}