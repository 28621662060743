import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import { FormScaffold } from './StepperFormScaffold';
import { PageTemplate } from './StepperFormUtils';
import { HCRC_Stringify } from '../Utils';

export const StepperForm = (props) => {

    const [inputs, setInputs] = useState(props.prevSubmission);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [submitting, setSubmitting] = React.useState(false);
    const stepnums = props.formData.map(r => parseInt(r.StepNumber)).filter((v, i, a) => a.indexOf(v) === i);
    const steps = stepnums.map(n => ({
        Number: n,
        Label: props.formData.filter(r => r.StepNumber === n)[0]["Section"],
        Optional: false,
        Page: (data) => <PageTemplate formData={data} CurrentInputs={inputs} />
    }));

    const notifyParent = (event) => {
        let newInput = event.target.id ? String(event.target.id) : String(event.target.name);
        let newValue = String(event.target.value);
        console.debug("Updating " + newInput + " to " + newValue);
        setInputs({ ...inputs, [newInput]: newValue });
    }

    const checkDependency = (dependency, operator, value) => {
        switch (operator) {
            case "E":
                return String(inputs[dependency]) === value;
            case "LE":
                return parseFloat(inputs[dependency]) <= parseFloat(value);
            case "GE":
                return parseFloat(inputs[dependency]) >= parseFloat(value);
            case "G":
                return parseFloat(inputs[dependency]) > parseFloat(value);
            default:
                return true;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        if (props.onboarding) {
            props.sendToParent(inputs);
            setAlertMessage("Inputs captured! Continue to the next step.")
            setShowAlert(true);
            setSubmitting(false);
            console.debug("Historic Assumption inputs captured. Moving on to next step.");
        }
        else {
            setShowAlert(false);
            console.debug("Submitting inputs...");
            try {
                let jsonPayload = HCRC_Stringify(inputs)
                let reqOptions = {
                    method: "POST",
                    headers: props.submitHeaders,
                    body: jsonPayload
                };
                let response = await fetch(props.endpoint, reqOptions);
                console.log(response);
                setAlertMessage("Success submitting inputs!");
            }
            catch (exception_var) {
                console.log("Error submitting inputs");
                console.log(exception_var);
                setAlertMessage("Error submitting inputs! " + exception_var);
                setAlertSeverity("error");
            }
            finally {
                setShowAlert(true);
                setSubmitting(false);
            }
        }
    }

    const isHidden = (input) => {
        if (input.DependencyInputs !== null) {
            let dependencyInputs = input.DependencyInputs.split(",");
            let dependencyIds = dependencyInputs.map(i => String(props.formData.filter(r => r.Input === i)[0].Id));
            let dependencyOperators = input.DependencyOperators.split(",");
            let dependencyValues = input.DependencyValues.split(",");
            for (let i = 0; i < dependencyInputs.length; i++) {
                if (Object.keys(inputs).includes(dependencyIds[i])) {
                    if (!checkDependency(dependencyIds[i], dependencyOperators[i], dependencyValues[i])) {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
            return false
        }
        else {
            return false;
        }
    }

    // Final data transformations

    const moddedFormData = props.formData.map(d => ({ ...d, Callback: (event) => notifyParent(event), "isHidden": isHidden(d) }));
    moddedFormData.forEach(r => (r.isHidden && r.Disabled && Object.keys(inputs).includes(String(r.Id))) ? delete inputs[r.Id] : null); // Removing newly hidden elements and disabled elements from inputs if they exist

    // Final Object creation for form scaffold

    const staffFormObjs = steps.map(s => ({ ...s, Data: moddedFormData.filter(r => r.StepNumber === s.Number) }));

    return (
        <div style={props.style}>
            <FormScaffold submitMethod={handleSubmit} submitDisabled={Object.keys(inputs).length === 0 || (new Set(Object.keys(inputs).map(k => inputs[k]))).size===1} submitting={submitting} steps={staffFormObjs} formName={props.formName} onboarding={props.onboarding} />
            <Alert hidden={!showAlert} severity={alertSeverity} style={{ width: "425px", height: "50px" }}>{alertMessage}</Alert>
        </div>
    );
}
